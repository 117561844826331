import { mapGetters } from 'vuex';

import config from '@/config';
import { Roles } from '@/enums/roles';
import RnField from '../field/field.vue';
import RnIcon from '../icon/icon.vue';
import RnBrand from '../brand/brand.vue';

export default {
	name: 'rn-navigation',
	components: {
		RnBrand,
		RnIcon,
		RnField,
	},
	props: {
		solid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			flyoutOpen: false,
			isMenuOpen: false,
		};
	},
	computed: {
		...mapGetters(['defaultRole', 'unreadNotificationsCount', 'modeCpOperationsOrSpoc']),
		primaryMenu() {
			switch (this.defaultRole.name) {
				case Roles.PUP:
					return [
						{
							to: { name: 'Dashboard' },
							text: this.$t('navigation.dashboard'),
						},
						{
							to: { name: 'PUPCollections' },
							text: this.$t('navigation.collections'),
						},
						// Temp disabled, since PUP's do not need access to invoices for now
						// https://studiohyperdrive.atlassian.net/browse/RENEO-372
						// {
						// 	to: { name: "PUPInvoicing" },
						// 	text: this.$t("navigation.invoices"),
						// },
						{
							to: { name: 'Resources' },
							text: this.$t('navigation.resources'),
						},
					];
				case Roles.CP:
					if (this.modeCpOperationsOrSpoc === 'operations') {
						return [
							{
								to: { name: 'Dashboard' },
								text: this.$t('navigation.dashboard'),
							},
							{
								to: { name: 'CPCollections' },
								text: this.$t('navigation.collections'),
							},
							// {
							// 	to: { name: 'CPOpsInvoices' },
							// 	text: this.$t('navigation.invoices'),
							// },
							{
								to: { name: 'CPInvoices' },
								text: this.$t('navigation.invoices'),
							},
							{
								to: { name: 'CPWarehouse' },
								text: this.$t('navigation.warehouse'),
							},
							{
								to: { name: 'Resources' },
								text: this.$t('navigation.resources'),
							},
						];
					}
					return [
						{
							to: { name: 'Dashboard' },
							text: this.$t('navigation.dashboard'),
						},
						{
							to: { name: 'CPInvoices' },
							text: this.$t('navigation.invoices'),
						},
						{
							to: { name: 'Resources' },
							text: this.$t('navigation.resources'),
						},
					];

				case Roles.RC:
					return [
						{
							to: { name: 'Dashboard' },
							text: this.$t('navigation.dashboard'),
						},
						{
							to: { name: 'RCOrders' },
							text: this.$t('navigation.deliveries'),
						},
						{
							to: { name: 'Resources' },
							text: this.$t('navigation.resources'),
						},
					];
				case Roles.SD:
					return [
						{
							to: { name: 'Dashboard' },
							text: this.$t('navigation.dashboard'),
						},
						{
							to: { name: 'RCOrders' },
							text: this.$t('navigation.deliveries'),
						},
						{
							to: { name: 'Resources' },
							text: this.$t('navigation.resources'),
						},
					];
				case Roles.MF:
					return [
						{
							to: { name: 'Dashboard' },
							text: this.$t('navigation.dashboard'),
						},
						{
							to: { name: 'MFContracts' },
							text: this.$t('navigation.contracts'),
						},
						{
							to: { name: 'MFPickupPoints' },
							text: this.$t('navigation.pickupPoints'),
						},
						{
							to: { name: 'Resources' },
							text: this.$t('navigation.resources'),
						},
					];
				default:
					return [];
			}
		},
		topMenu() {
			switch (this.defaultRole.name) {
				case Roles.PUP:
					return [];
				case Roles.CP:
					return [
						{
							mode: 'operations',
							text: this.$t('navigation.operations'),
						},
						{
							mode: 'spoc',
							text: this.$t('navigation.spoc'),
						},
					];
				case Roles.RC:
					return [];
				case Roles.SD:
					return [];
				default:
					return [];
			}
		},
		currentLanguage: {
			get() {
				return this.$store.getters.currentLanguage;
			},
			set(newLanguage) {
				const { name, params } = this.$router.history.current;
				if (params.language !== newLanguage) {
					this.$router.push({
						name,
						params: {
							...params,
							language: newLanguage,
						},
					});
				}
			},
		},
		selectFieldProps() {
			return {
				inputProps: {
					id: 'navigation-language-select',
					options: config.supportedLanguages,
					type: 'select',
				},
				inverted: true,
			};
		},
	},
	methods: {
		changeMode(mode) {
			this.$store.dispatch('setModeCpOperationsOrSpoc', mode);

			if (mode === 'spoc' && this.$route.name !== 'Resources') {
				this.$router.push({ name: 'Dashboard' });
			}
			if (mode === 'operations' && this.$route.name !== 'Resources') {
				this.$router.push({ name: 'Dashboard' });
			}
		},
		toggleFlyout(flyoutState = false) {
			if (this.flyoutOpen !== flyoutState) {
				this.flyoutOpen = flyoutState;
			}
		},
		toggleMenu(menuState = false) {
			if (this.isMenuOpen !== menuState) {
				this.isMenuOpen = menuState;
			}
		},
		logout() {
			this.$store.dispatch('logout');
			this.$router.push({
				name: 'Login',
				params: this.$router.history.current.params,
			});
		},
	},
};
