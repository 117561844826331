import get from 'lodash/get';

import RnIcon from '../../icon/icon.vue';
import RnButton from '../../button/button.vue';

export default {
	name: 'rn-dropzone',

	components: {
		RnIcon,
		RnButton,
	},

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		interactive: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		id() {
			return get(this.data, "id", "");
		},

		noPackaging() {
			return get(this.data, "noPackaging", false);
		},

		tempId() {
			return get(this.data, "tempId", "");
		},

		icon() {
			return get(this.data, "icon", "custom-package");
		},

		title() {
			return get(this.data, "title", "");
		},

		subtitle() {
			return get(this.data, "subtitle", "");
		}
	},

	methods: {
		remove() {
			const el = this.$refs.zone;

			this.$emit('remove', this.tempId || this.id, el);
		}
	}
}
