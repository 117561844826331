import Vue from 'vue';
import Vuex from 'vuex';

import global from './modules/global';

import user from './modules/user';
import brands from './modules/brands';
import batteries from './modules/batteries';
import batteryDescriptions from './modules/battery-descriptions';
import navigation from './modules/navigation';
import packagings from './modules/packaging';
import resources from './modules/resources';
import collectionOrders from './modules/collection-orders';
import locations from './modules/locations';
import forms from './modules/forms';
import message from './modules/message';
import pupInvoices from './modules/pup/pup-invoices';
import opsInvoices from './modules/cp/invoices-ops';
import spocInvoices from './modules/cp/invoices-spoc';
import mfPickupPoints from './modules/mf/pickup-points';
import contracts from './modules/contracts';
import metadata from './modules/metadata';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		global,
		user,
		brands,
		batteries,
		batteryDescriptions,
		navigation,
		packagings,
		resources,
		collectionOrders,
		locations,
		forms,
		message,
		pupInvoices,
		opsInvoices,
		spocInvoices,
		mfPickupPoints,
		contracts,
		metadata,
	},
});

export default store;
