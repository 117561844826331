import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-alert',
	components: {
		RnIcon,
	},
	props: {
		message: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: 'warning',
		},
	},
};
