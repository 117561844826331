import RnIcon from '../icon/icon.vue';
import RnButton from '../button/button.vue';

export default {
	name: 'rn-resource-card',
	components: {
		RnIcon,
		RnButton,
	},
	props: {
		showDelete: {
			type: Boolean,
			default: false,
		},
		resource: {
			type: Object,
			required: true,
		},
	},
	methods: {
		downloadFile(fileName) {
			window.open(`/server/resources/file/${fileName}`, '_blank');
		},
	},
};
