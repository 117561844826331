import { mapGetters } from 'vuex';
import { cloneDeep, groupBy, sumBy, get, map } from 'lodash';

import { Roles } from '@/enums/roles';
import apiService from '@/services/api';
import { RnButton, RnField, RnIcon, RnHeader, RnNewTable, RnCardList, RnSpinner, RnSidebar, RnDropzoneManager, RnDropzones, RnTag, RnBatteryList, RnForm} from '@/components';
import { batteriesMixin, transportPackagingFormMixin } from '@/mixins';
import { MessageTypes } from '@/enums/message-types';
import { generateHash, logAndToastError } from '@/utils';
import { downloadAttachment } from '@/utils/download-attachment';

import { setPackaging } from './new-collection.service';

export default {
	name: 'rn-cp-new-collection-view',
	components: {
		RnButton,
		RnField,
		RnIcon,
		RnHeader,
		RnNewTable,
		RnCardList,
		RnSpinner,
		RnSidebar,
		RnDropzoneManager,
		RnDropzones,
		RnTag,
		RnBatteryList,
		RnForm,
	},
	mixins: [batteriesMixin, transportPackagingFormMixin],
	data() {
		return {
			state: {
				currentStep: 1,
				date: null,
				destination: null,
				saveInProgress: false,
				destinationError: null,
				sidebarOpen: false,
				selectedPackaging: [],
				transportationPackaging: [],
				renderDropzones: true,
				defaultZoneCount: 0,
			},
			loading: false,
		};
	},
	computed: {
		...mapGetters(['locationId', 'businessRoles', 'businessRelationId', 'locationsByRoles', 'collectionOrders', 'collectionOrderTransportPackagings']),
		collectionOrder() {
			return get(this.collectionOrders, '[0]', null);
		},
		destinationLocations() {
			return this.locationsByRoles.map(location => ({
				name: location.name,
				value: location.id,
			}));
		},
		location() {
			return this.$store.getters.locationsLocation(this.collectionOrder.sourceLocation.id);
		},
		transportPackagingCardList() {
			if (!this.collectionOrderTransportPackagings || !this.collectionOrderTransportPackagings.length) {
				return null;
			}

			const transformedTransportationPackaging = map(groupBy(this.transportationPackaging, 'id'), (o, idx) => {
				const data = this.transportationPackaging.find((item) => item.id === idx);

				return {
					...data,
					amount: sumBy(o, 'amount')
				}
			});

			return transformedTransportationPackaging.reduce((acc, curr) => {
				const index = acc.findIndex(item => item.id === curr.id);

				index > -1 ? acc[index].name = curr.name : acc.push({
					grid: { cols: 3 },
					icon: {
						name: curr.icon,
						value: curr.amount
					},
					info: [
						{
							label: curr.name,
							value: `${curr.width} x ${curr.depth} x ${curr.height} ${this.$t('common.mm')} • ${curr.weight} ${this.$t('common.kg')}`,
						},
					],
				});

				return acc;
			}, []);
		},
		collectionOrdersState() {
			if (!this.collectionOrders) {
				return 'loading';
			}
			if (this.collectionOrders[0]) {
				return 'results';
			}
			return 'noResults';
		},

		address() {
			return `${get(this.location, "name", "")},
				${get(this.location, "addresses[0].street", "")} ${get(this.location, "addresses[0].number", "")}, ${get(this.location, "addresses[0].zipCode", "")} ${get(this.location, "addresses[0].city", "")}`
		},

		phone() {
			return get(this.location, "phone", "");
		},

		email() {
			return get(this.location, "email", "");
		},

		amount() {
			return get(this.collectionOrder, "batteryCount", 0);
		},

		selectedBrandId() {
			const batteries = get(this.collectionOrder, "batteries", []);
			const battery = get(batteries, "[0]", {});
			const batteryDescription = get(battery, "batteryDescription", {});

			return get(batteryDescription, "brand.id", '');
		},

		weight() {
			const batteriesWeight = get(this.collectionOrder, "batteries", []).reduce((prev, curr) => prev + curr.nettoWeight, 0);
			const packagingWeight = this.collectionOrderTransportPackagings.reduce((prev, curr) => prev + curr.weight, 0);

			return batteriesWeight + packagingWeight;
		},

		batteries() {
			return get(this.collectionOrder, 'batteries', []);
		},

		customPackagings() {
			return this.batteries.filter((battery) =>
				!get(battery, 'batteryPackaging.suitedForTransport', false) && !get(battery, 'transportPackaging.suitedForTransport', false)
			);
		},

		customPackagingAllowed() {
			return this.customPackagings.length > 0;
		},

		transportationPackaging() {
			return [...this.collectionOrderTransportPackagings, ...this.state.transportationPackaging];
		}
	},
	watch: {
		collectionOrders(newOrders) {
			const locationId = get(newOrders, '[0].sourceLocation.id');
			const collectionOrderId = get(newOrders, '[0].id');
			if (locationId) {
				this.$store.dispatch('getLocation', { locationId });
				if (collectionOrderId) {
					this.$store.dispatch('getCollectionOrderPackagings', { collectionOrderId });
				}
			}
			this.attemptToShowTransportWarning();
		},
		collectionOrderTransportPackagings() {
			this.attemptToShowTransportWarning();
		},
		businessRoles: {
			immediate: true,
			handler(value) {
				if (value.length) {
					const roles = [Roles.RC, Roles.SD];
					const roleIds = this.$store.getters.businessRoleIds(roles);

					this.$store.dispatch('getLocationsByRoles', { roleIds, businessRelationId: this.businessRelationId });
				}
			},
		},
	},
	created() {
		this.$store.dispatch('getCollectionOrder', {
			businessRelationId: this.businessRelationId,
			collectionOrderId: this.$route.params.collectionOrderId,
		});
		this.$store.dispatch('getCollectionOrderPackagings', { collectionOrderId: this.$route.params.collectionOrderId });
	},
	methods: {
		attemptToShowTransportWarning() {
			if (!this.collectionOrders || !this.collectionOrderTransportPackagings) {
				return;
			}
			const packagingWarningMessages = [];
			if (!this.collectionOrderTransportPackagings[0]) {
				packagingWarningMessages.push(this.$t('error.newCollection.noTransportPackaging'));
			}
			if (this.collectionOrders[0].batteries.filter(battery => !battery.batteryPackaging).length) {
				packagingWarningMessages.push(this.$t('error.newCollection.noBatteryPackaging'));
			}
			if (packagingWarningMessages.length) {
				this.$store.dispatch('setMessage', {
					text: packagingWarningMessages.join('\n'),
					type: MessageTypes.WARNING,
				});
			}
		},
		startDownloadDeliveryNote() {
			downloadAttachment(this.collectionOrders[0].packingSlipId, 'message.deliveryOrder.error', this.$store);
		},
		setFormValue(prop, value) {
			this.state[prop] = value;
			if (this.state.destinationError) {
				this.validateForm();
			}
		},
		validateForm() {
			this.state.destinationError = this.state.destination ? null : this.$t('error.required', [this.$t('common.destination')]);

			return this.state.destination;
		},
		scheduleCollection() {
			if (!this.validateForm()) {
				return;
			}

			this.state.saveInProgress = true;
			const url = `/businessrelations/${this.businessRelationId}/collectionorders/${this.collectionOrder.id}`;
			const body = {
				plannedPickupDate: this.state.date ? this.state.date : null,
				destinationLocationId: this.state.destination,
			};
			apiService
				.patch(url, body)
				.then(({ data: response }) => {
					if (response.success) {
						this.$store.dispatch('setMessage', {
							text: 'message.scheduleCollection.success',
							type: MessageTypes.SUCCESS,
						});
						this.$router.push({ name: 'Dashboard' });
					}
					this.state.saveInProgress = false;
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to scheduleCollection', err, {
						url,
						body,
					});
					this.state.saveInProgress = false;
				});
		},

		goBack() {
			this.$router.push({ name: 'Dashboard' });
		},

		async toggleSidebar(openState) {
			await this.$store.dispatch('getTransportPackagings', {
				businessRelationId: this.businessRelationId,
				brandId: this.selectedBrandId,
			});
			this.state.sidebarOpen = openState;
		},

		handleDropzone(data) {
			const battery = this.customPackagings.find((battery) => battery.id === data.draggableId);

			battery.transportPackagingId = data.dropzoneId ? data.dropzoneId : null;
		},

		transportPackagingZones() {
			return this.state.selectedPackaging ? this.state.selectedPackaging.map((packaging) => {
				return {
					...packaging,
					icon: packaging.icon ? packaging.icon.name : "custom-package",
					title: packaging.name,
					subtitle: `${packaging.width} x ${packaging.height} x ${packaging.depth} ${this.$t('common.mm')}`,
				}
			}) : [];
		},

		removePackaging(tempId) {
			const packagings = cloneDeep(this.state.selectedPackaging);
			const toRemoveId = packagings.find(packaging => tempId === packaging.tempId);
			const toRemoveIdIndex = packagings.indexOf(toRemoveId);

			packagings.splice(toRemoveIdIndex, 1);

			Object.keys(packagings).forEach(key => {
				if (packagings[key].length === 0) {
					delete packagings[key];
				}
			});

			this.state.selectedPackaging = [...packagings];

			this.forceDropzonesRerender();
		},

		forceDropzonesRerender() {
			this.state.renderDropzones = false;

			this.$nextTick(() => {
				this.state.renderDropzones = true;
			});
		},

		addTransportPackaging() {
			if (this.validateTransportPackagingFormModel(this.transportPackagingFormModel)) {
				const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, this.selectedBrandId);

				this.transportPackagingFormModel.packagings
					.map((item) => {
						const transportPackaging = transportPackagings.find(packaging => packaging.id === item.id) || {};

						for (let i = 0; i < item.value; i++) {
							const packagingInfo = {
								...this.transportPackagingFormModel.transportPackaging,
								amount: item.value,
								tempId: generateHash(),
							};

							this.state.selectedPackaging.push({
								...transportPackaging,
								...packagingInfo,
							});
						}
					});

				this.resetFormModel(this.transportPackagingFormModel);
				this.state.currentStep = 1;
				this.forceDropzonesRerender();
			}
		},

		async addCustomTransportPackaging() {
			const valid = this.validateTransportPackagingFormModel(this.transportPackagingFormModel, true);

			if (!valid) {
				return;
			}

			const { name, width, height, depth, weight, packagings } = this.transportPackagingFormModel;

			try {
				const id = await setPackaging({
					name,
					suitedForTransport: true, // Transport packagings are always suited for transport.
					height,
					width,
					depth,
					weight,
					packagingIconId: '',
					packagingTypeId: '21975384-b572-481f-956b-a93448b19eed',
				}, this.locationId);

				if (id) {
					await this.$store.dispatch('getTransportPackagings', {
						businessRelationId: this.businessRelationId,
						brandId: this.selectedBrandId,
					});

					this.resetAddPackagingFormModel();
					this.updateTransportPackagingFormModel({
						packagings: [
							...packagings,
							{
								id: id,
								value: 1,
							}
						],
					});
				}
			} catch (err) {
				logAndToastError(this.$store.dispatch, 'Failed to send custom packaging to server', err);
			}
		},

		forceDropzonesRerender() {
			this.state.renderDropzones = false;

			this.$nextTick(() => {
				this.state.renderDropzones = true;
			});
		},

		updateDefaultZoneCount(zoneCount) {
			this.state.defaultZoneCount = zoneCount;
		},

		savePackaging() {
			const batteries = get(this.collectionOrder, 'batteries', []);
			const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, this.selectedBrandId);

			batteries.map((battery) => {
				const selectedTransportPackaging = transportPackagings.find((packaging) => packaging.id === battery.transportPackagingId);

				if (!battery.transportPackaging) {
					this.state.transportationPackaging.push({
						amount: 1,
						...selectedTransportPackaging
					});

					return battery.transportPackaging = {
						...selectedTransportPackaging
					};
				}
			})

			this.toggleSidebar(false);
		}
	},
};
