import RnIcon from '@/components/icon/icon.vue';
import RnButton from '@/components/button/button.vue';

import { downloadAttachment } from '@/utils/download-attachment';

export default {
	name: 'rn-file-item',
	components: {
		RnIcon,
		RnButton,
	},
	props: {
		attachment: {
			type: Object,
			default: {},
		},
		deleteHandler: {
			type: Function,
			default: () => {
				// do nothing
			},
		},
	},
	methods: {
		downloadFile() {
			const newAttachment = {
				...this.attachment.fileInfo,
				fileNameWithExtension: this.attachment.fileInfo.fileName,
			};

			downloadAttachment(newAttachment);
		},
	},
};
