import get from 'lodash/get';

export default {
	name: 'rn-tabs',

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		currentTabIndex: {
			type: Number,
			required: true,
			default: 0,
		}
	},

	methods: {
		click(index) {
			const category = get(this.data[index], "category", "");

			this.$emit("click", index, category);
		}
	}
};
