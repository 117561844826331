import { mapGetters } from 'vuex';
import RnButton from '../button/button.vue';

export default {
	name: 'rn-sidebar',
	components: {
		RnButton,
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		withOverlay: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters(['isLocked']),

		hasDefaultSlot() {
      return !!this.$slots.action
		},
	},
	watch: {
		close() {
			this.close();
		},
		open() {
			if (!this.open) {
				this.removeScrollLock();
			}
		},
	},
	created() {
		this.setScrollLock();
	},
	updated() {
		this.setScrollLock();
	},
	destroyed() {
		this.removeScrollLock();
	},
	methods: {
		close() {
			this.$emit('update:open', false);
		},
		setScrollLock() {
			if (this.open && !this.isLocked) {
				this.$store.dispatch('setScrollLock', true);
			}
		},
		removeScrollLock() {
			this.$store.dispatch('setScrollLock', false);
		},
	},
};
