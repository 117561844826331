import get from 'lodash/get';

import { MessageTypes } from '@/enums/message-types';
import logger from '../services/logger';
import i18n from '../i18n';

export function logAndToastError(dispatchOrCommitFunction, errorMessage, err, extraInfo) {
	logger.error(errorMessage, err, extraInfo);
	if (typeof dispatchOrCommitFunction === 'function') {
		let text;
		const serverErrors = get(err, 'response.data.errors');
		if (serverErrors && serverErrors.length) {
			text = `${i18n.te(errorMessage) ? i18n.tc(errorMessage) : errorMessage}: ${serverErrors.map(error => i18n.te(`error.${error.code}`) ? i18n.tc(`error.${error.code}`) : error.message).join('  ')}`;
		} else {
			text = errorMessage;
		}
		dispatchOrCommitFunction('setMessage', { text, type: MessageTypes.ERROR });
	} else {
		logger.error('Called logAndToastError with invalid dispatchOrCommitFunction. dispatchOrCommitFunction should be a function', null, {
			dispatchOrCommitFunction,
		});
	}
}
