import RnIcon from '@/components/icon/icon.vue';
import RnButton from '@/components/button/button.vue';
import { fieldMixin } from '@/mixins';

export default {
	name: 'cs-field-combobox',
	components: {
		RnIcon,
		RnButton,
	},
	mixins: [fieldMixin],
	data() {
    return {
      isOpen: false,
			searchValue: "",
			values: this.options.map(option => option.value) || [],
    }
  },
	computed: {
		selectedCount() {
			return this.values.length;
		},
		visibleSelectedCount() {
			return this.values.length > 9 ? "9+" : this.values.length;
		},
		label() {
			return `${this.$t('common.search')} ${this.placeholder.split(/(?=[A-Z])/).join(" ").toLowerCase()}...`;
		},
		optionsWithSearch() {
			return [
				...(!!this.searchValue ? [{
					value: this.searchValue,
					name: this.searchValue,
				}] : []),
				...this.options.map(option => option.value),
			];
		}
	},
	watch: {
		searchValue(newValue, oldValue) {
			if (!!newValue && newValue !== oldValue) {
				this.values = this.optionsWithSearch.map(option => option.value);
			}
		}
  },
	methods: {
		setIsOpen(state) {
			this.isOpen = state;

			if (state) {
				this.$nextTick(() => {
					this.$refs.searchInput.focus();
				});
			}
		},
		clearValue() {
			this.searchValue = "";
		},
		submit() {
			this.$emit('update:value', this.values);
			this.setIsOpen(false);
		}
	},
};
