import { mapGetters } from 'vuex';

import { RnNewTable, RnCardList, RnStepForm } from '@/components';

import { MessageTypes } from '@/enums/message-types';
import { batteriesMixin, transportPackagingFormMixin, editBatteryFormMixin } from '@/mixins';
import { getBatteryStatusInfo, prepareAttachments, removeAttachment } from '@/services/new-battery';
import RnForm from '../form/form.vue';
import RnButton from '../button/button.vue';
import RnIcon from '../icon/icon.vue';
import RnField from '../field/field.vue';
import RnSidebar from '../sidebar/sidebar.vue';
import RnAccordeon from '../accordeon/accordeon.vue';
import RnFileItem from '../fileItem/fileItem.vue';
import RnSpinner from '../spinner/spinner.vue';

export default {
	name: 'rn-edit-battery-sidebar',
	components: {
		RnForm,
		RnField,
		RnButton,
		RnIcon,
		RnNewTable,
		RnCardList,
		RnStepForm,
		RnSpinner,
		RnSidebar,
		RnAccordeon,
		RnFileItem,
	},
	mixins: [batteriesMixin, transportPackagingFormMixin, editBatteryFormMixin],
	props: {
		isEditing: {
			type: Boolean,
			default: false,
			required: true,
		},
		battery: {
			type: Object,
			default: {},
			required: true,
		},
		open: {
			type: Boolean,
			default: false,
		},
		saveBattery: {
			type: Function,
			default: () => {
				// do nothing
			},
		},
	},
	data() {
		return {
			currentStep: 1,
			renderPackaging: false,
			isOpen: false,
			isUploadingAttachments: false,
		};
	},
	watch: {
		isOpen() {
			this.$emit('update:open', this.isOpen);
		},
	},
	created() {
		this.$store.dispatch('getChemicalFamilies');
		this.$store.dispatch('getBatteryConditions');
		this.$store.dispatch('getBatteryPackagings', {
			businessRelationId: this.businessRelationId,
			brandId: this.battery.batteryDescription.brand.id,
		});

		this.isOpen = this.open;

		this.setFormModel();
	},
	computed: {
		...mapGetters([
			'businessRoles',
			'locationId',
			'location',
			'locations',
			'locationsByRoles',
			'checkedBatteries',
			'checkedBatteriesCount',
			'businessRelationId',
			'chemicalFamilies',
			'itemsByLocationAndBrand',
			'batteryConditions',
		]),
		batteryStatusInfo() {
			return getBatteryStatusInfo(this.$t, this.batteryConditions);
		},
		activeBatteryStatus() {
			return this.batteryStatusInfo.find((status) => status.status === this.battery.batteryCondition);
		},
	},
	methods: {
		setFormModel() {
			this.batteryFormModel = {
				...this.batteryFormModel,
				...this.battery.batteryDescription,
				id: this.battery.id,
				brand: this.battery.batteryDescription.brand.id,
				chemicalFamilyTypeId: this.battery.batteryDescription.chemicalFamilyType.id,
				damagedDescription: this.battery.damagedDescription,
				batteryCondition: this.battery.batteryCondition,
				constructionYear: this.battery.batteryDescription.constructionYear,
				batteryPackagingId: this.battery.batteryPackaging ? this.battery.batteryPackaging.id : null,
				nettoWeight: this.battery.batteryDescription.nettoWeight,
				width: this.battery.batteryDescription.width,
				height: this.battery.batteryDescription.height,
				depth: this.battery.batteryDescription.depth,
				customBatteryPackaging: !!this.battery.packaging,
			};
		},
		handleRenderPackaging() {
			this.renderPackaging = !this.renderPackaging;
		},
		getModelUpdater(model) {
			this.editBatteryForm.modelEditHandler(model);
		},
		editBatteryValidateAndCompleteForm() {
			// Validate part of the addBattery model
			if (this.validateBatteryFormModel(this, this.batteryFormModel, this.batteryStateChanged())) {
				this.completeForm();
				this.closeModal();
			} else {
				this.validateBatteryFormModel(this, this.batteryFormModel, this.batteryStateChanged());
			}
		},
		batteryStateChanged() {
			return this.battery.damaged !== this.batteryFormModel.damaged || this.battery.isBatteryStable !== this.batteryFormModel.isBatteryStable;
		},
		downloadChecklist() {
			// TODO download checklist from resources (RENEO-262)
			this.$store.dispatch('setMessage', { text: 'message.noChecklistAvailable', type: MessageTypes.WARNING });
		},
		completeForm() {
			this.currentStep = 1;
			this.saveBattery(this.battery, this.batteryFormModel);
		},
		closeModal() {
			this.isOpen = false;
		},
		prepareAttachments(files, type) {
			prepareAttachments(this, files, type);
		},
		removeAttachment(attachmentId) {
			removeAttachment(this, attachmentId);
		},
		onSidebarOpenOrClosed(isOpen) {
			if (!isOpen) {
				this.resetFormModel(this.batteryFormModel);
				this.state.currentAddBatteryStep = 1;
				this.state.isEditing = false;
			}
		},
		changeStep(toStep) {
			if (toStep === 'previous') {
				this.$refs.stepForm.previousStep();

				return;
			}
			this.$refs.stepForm.toAddBatteryStep();
		},
	},
};
