export const ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE = Object.freeze({
	photo: {
		mimeTypes: ['image/jpeg', 'image/png', 'image/gif'],
		allowedExtensions: 'jpg, png, gif',
	},
	statusReport: {
		mimeTypes: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
		allowedExtensions: 'pdf, doc, docx',
	},
});
