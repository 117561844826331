import apiService from '@/services/api';
import { hasData } from '@/utils/service-helpers';

export const createAttachment = (fileInfo, type) =>
	apiService
		.post('/attachments', fileInfo)
		.then(hasData)
		.then(attachmentId => ({
			attachmentId,
			fileInfo,
			type,
		}));

export const linkAttachmentToBattery = (attachmentId, batteryId) => apiService.post(`/batteries/${batteryId}/attachments?attachmentId=${attachmentId}`);
