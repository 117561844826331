import { mapGetters } from 'vuex';

import { interpolate } from '@/utils/string';
import apiService from '@/services/api';
import logger from '@/services/logger';

import RnIcon from '../icon/icon.vue';
import RnButton from '../button/button.vue';

export default {
	name: 'rn-important-notifications',
	components: {
		RnIcon,
		RnButton,
	},
	computed: {
		...mapGetters([
			'userId',
			'importantNotifications',
			'currentLanguage',
		]),
	},
	methods: {
		message(notification) {
			const notificationTranslationKey = `notifications.${notification.code}`;
			let messageTemplate = this.$t(notificationTranslationKey);
			if (messageTemplate === notificationTranslationKey) {
				messageTemplate = notification.messageTemplate;
			}

			let notificationParams = {};
			if (messageTemplate.includes("{attachmentID}")) {
				notificationParams = {
					...notification.parameters,
					attachmentID: `<a href="/release-notes/20240301 Reneos Software Release Notes v1.3_${this.currentLanguage}.pdf" target="_blank" style="text-decoration: underline;">${this.$t("resources.download").toLowerCase()}</a>`,
				};
			}

			return interpolate(messageTemplate, notificationParams || {});
		},
		markAsRead(id) {
			const url = `/users/${this.userId}/notification/${id}`;
			const body = { seenOn: new Date() };
			apiService
				.patch(url, body)
				.then(({ data: response }) => {
					if (response.success) {
						this.$store.dispatch('getAllNotifications');
					}
				})
				.catch(err => {
					logger.error('Failed to mark notification as read', err, {
						url,
						body,
					});
				});
		},
	}
};
