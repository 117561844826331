export default {
	name: 'rn-data-list',
	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		isValueArray(value) {
			return Array.isArray(value);
		}
	}
};
