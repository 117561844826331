// Pickup points store module
// ---
//
// ------------------------------------------------------------------------- /
import { get } from 'lodash';
import hash from 'object-hash';

const BUSINESS_RELATION_STORAGE_KEY = 'reneos.businessRelation.hash';

// Helpers
function getBusinessRelationFromState(state) {
	return {
		name: state.businessRelation.name,
		language: state.businessRelation.language.substring(0, 2),
		email: state.businessRelation.email,
		phone: state.businessRelation.phone,
	};
}

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	businessRelation: null,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	businessRelation: state => state.businessRelation,
	isBusinessRelationChangesPending: state => get(state, 'isBusinessRelationChangesPending', initialState.isBusinessRelationChangesPending),
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	/**
	 * Checks if the user is still logged in on the server using the session cookie
	 * If they are, fetch the user info from the server session
	 * @param dispatch
	 */
	setBusinessRelation({ commit }, businessRelation) {
		commit('setBusinessRelation', businessRelation);
	},
	updateContactInformation({ dispatch, state }, newInfo) {
		const oldInfo = {
			name: state.businessRelation.name,
			language: state.businessRelation.language.substring(0, 2),
			email: state.businessRelation.email,
			phone: state.businessRelation.phone,
		};

		// apiService
		// 	.post(
		// 		`/businessrelations/${id}/tasks`,
		// 		getTaskInfo(
		// 			state.user,
		// 			oldInfo,
		// 			newInfo,
		// 			'Reneos: Request to change contact information',
		// 			`The contact info has been updated for ${state.user.firstName} ${state.user.lastName}`,
		// 			`${state.envs.EDIT_PERSON_ENDPOINT}/${state.user.personId}`
		// 		)
		// 	)
		// 	.then(({ data: response }) => {
		// 		if (response.success) {
		// 			dispatch('setMessage', {
		// 				text: 'message.contact.success',
		// 				type: MessageTypes.SUCCESS,
		// 			});

		// 			// Store old contact detail, so we can track when the info from the server has changed, then we know the reneos employee as updated the contact info
		// 			localStorage.setItem(BUSINESS_RELATION_STORAGE_KEY, hash(getBusinessRelationFromState(state)));
		// 			dispatch('updateBusinessRelationChangesPending');
		// 		} else {
		// 			dispatch('setMessage', {
		// 				text: 'message.contact.error',
		// 				type: MessageTypes.ERROR,
		// 			});
		// 		}
		// 	})
		// 	.catch(error => {
		// 		dispatch('setInfoFormErrors', {
		// 			form: 'contactInformation',
		// 			errors: error.response,
		// 		});
		// 	});
	},
	addPickupPoint({ dispatch, state }, data) {
		// apiService
		// 	.post(
		// 		`/businessrelations/${id}/tasks`,
		// 		getTaskInfo(
		// 			state.user,
		// 			oldInfo,
		// 			newInfo,
		// 			'Reneos: Request to change contact information',
		// 			`The contact info has been updated for ${state.user.firstName} ${state.user.lastName}`,
		// 			`${state.envs.EDIT_PERSON_ENDPOINT}/${state.user.personId}`
		// 		)
		// 	)
		// 	.then(({ data: response }) => {
		// 		if (response.success) {
		// 			dispatch('setMessage', {
		// 				text: 'message.contact.success',
		// 				type: MessageTypes.SUCCESS,
		// 			});

		// 			// Store old contact detail, so we can track when the info from the server has changed, then we know the reneos employee as updated the contact info
		// 			localStorage.setItem(BUSINESS_RELATION_STORAGE_KEY, hash(getBusinessRelationFromState(state)));
		// 			dispatch('updateBusinessRelationChangesPending');
		// 		} else {
		// 			dispatch('setMessage', {
		// 				text: 'message.contact.error',
		// 				type: MessageTypes.ERROR,
		// 			});
		// 		}
		// 	})
		// 	.catch(error => {
		// 		dispatch('setInfoFormErrors', {
		// 			form: 'contactInformation',
		// 			errors: error.response,
		// 		});
		// 	});
	},
	updateBusinessRelationChangesPending({ commit, state }) {
		let oldBusinessRelationHash;
		try {
			oldBusinessRelationHash = localStorage.getItem(BUSINESS_RELATION_STORAGE_KEY);
		} catch (err) {
			// Remove the localstorage item if it is malformed
			localStorage.removeItem(BUSINESS_RELATION_STORAGE_KEY);
		}
		if (!oldBusinessRelationHash) {
			commit('setBusinessRelationChangesPending', false);

			return;
		}

		const currentBusinessRelationHash = hash(getContactInfoFromState(state));

		// if old info is still equal to the current, then the change has not been inserted by the Reneos employee
		const isChangePending = currentBusinessRelationHash === oldBusinessRelationHash;

		if (!isChangePending) {
			// An update has happened => we should delete the local storage item
			localStorage.removeItem(BUSINESS_RELATION_STORAGE_KEY);
		}

		commit('setBusinessRelationChangesPending', isChangePending);
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setBusinessRelation(state, businessRelation) {
		state.businessRelation = businessRelation;
	},
	setBusinessRelationChangesPending(state, isChangePending) {
		state.isBusinessRelationChangesPending = isChangePending;
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
