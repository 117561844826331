import get from "lodash/get";

import { MessageTypes } from '@/enums/message-types';
import apiService from '@/services/api';
import logger from '@/services/logger';

export const getLanguages = async () => {
	try {
		const res = await apiService.get('/crm/languages')

		return get(res, "data", null);
	} catch (error) {
		logger.error('Failed to fetch languages', error);
		this.$store.dispatch('setMessage', { text: get(error, "response.data.errors[0].message"), type: MessageTypes.ERROR });
	}
}

export const getCountries = async () => {
	try {
		const res = await apiService.get('/crm/countries')

		return get(res, "data", null);
	} catch (error) {
		logger.error('Failed to fetch countries', error);
		this.$store.dispatch('setMessage', { text: get(error, "response.data.errors[0].message"), type: MessageTypes.ERROR });
	}
}
