import { HeaderClasses, InnerClasses } from './header.enums';
import RnButton from '../button/button.vue';
import RnImportantNotifications from '../important-notifications/important-notifications.vue';

export default {
	name: 'rn-header',
	components: {
		RnButton,
		RnImportantNotifications,
	},
	props: {
		background: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: '',
		},
		center: {
			type: Boolean,
			default: false,
		},
		hasClose: {
			type: Boolean,
			default: false,
		},
		method: {
			type: Function,
			default: () => {},
		}
	},
	computed: {
		headerClasses() {
			return {
				[HeaderClasses.Base]: true,
				// Sizes
				[HeaderClasses.Small]: this.size === 'small',
			};
		},

		hasDefaultSlot () {
      return !!this.$slots.action
		},

		innerClasses() {
			return {
				[InnerClasses.container]: true,
				// Position
				[InnerClasses.center]: this.center,
			};
		},

		headerVisualUrl() {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const image = require(`@/assets/img/backgrounds/${this.background}`);

			return `url('${image}')`;
		},

		greeting() {
			const hours = new Date().getHours();

			if (hours >= 4 && hours < 12) {
				return this.$t('header.greetingMorning'); // 4 AM - 11:59AM
			}
			if (hours >= 12 && hours < 18) {
				return this.$t('header.greetingAfternoon'); // 12 AM - 5:59PM
			}
			return this.$t('header.greetingEvening'); // 6:00PM - 3:59AM
		},
	},
};
