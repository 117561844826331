import RnIcon from '@/components/icon/icon.vue';
import RnCard from '@/components/field/fields/amount/card/card.vue';
import { fieldMixin } from '@/mixins';

export default {
	name: 'cs-field-amount',

	components: {
		RnIcon,
		RnCard,
	},

	data() {
		return {
			cards: []
		}
	},

	mixins: [fieldMixin],

	methods: {
		change(data) {
			this.cards.push(data);

			const value = this.cards.reduce((acc, curr) => {
				const index = acc.findIndex(item => item.id === curr.id);

				index > -1 ? acc[index].value = curr.value : acc.push({
					id: curr.id,
					value: curr.value
				});

				return acc
			}, []);

			this.updateValue({
				target: {
					value
				}
			});
		}
	}
}
