import apiService from '@/services/api';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import RecursiveError from '@/services/recursiveError';
import { MessageTypes } from '@/enums/message-types';
import { FileTypes } from '@/enums/file-types';
import { ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE } from '@/views/app/pup/new-request/new-request.const';
import { createAttachment } from '@/services/attachment';
import { logAndToastError, withKgUnit, withMmUnit } from '@/utils';
import logger from '@/services/logger';

export const batteryFormKeys = {
	brand: null,
	model: null,
	chemicalFamily: null,
	constructionYear: null,
	nettoWeight: null,
	serialNumber: null,
	batteryDescriptionId: null,
	width: null,
	height: null,
	depth: null,
	partNumber: null,
	internalReference: null,
	suitedForTransport: false,
	damagedDescription: null,
	batteryPackagingId: null,
	transportPackagingId: null,
	batteryConditionId: null,
	customBatteryPackaging: null,
	noBatteryPackaging: false,
	photoAdded: null,
	statusReportAdded: null,
	packagingOption: null,
	attachments: [],
};

export const patchBattery = (batteryId, updatedValues) => {
	const url = `/batteries/${batteryId}`;

	return apiService.patch(url, updatedValues);
};

export function getBatteryDTO(batteryInfo) {
	return {
		internalReference: batteryInfo.internalReference,
		batteryConditionId: batteryInfo.batteryConditionId,
		damagedDescription: batteryInfo.damagedDescription,
		batteryDescriptionId: batteryInfo.batteryDescriptionId,
		...(!batteryInfo.batteryDescriptionId && { batteryDescription: {
			owner: batteryInfo.brand,
			batteryModelId: batteryInfo.model,
			constructionYear: batteryInfo.constructionYear,
			partNumber: batteryInfo.partNumber,
		}}),
		serialNumber: batteryInfo.serialNumber,
		batteryPackagingId: batteryInfo.batteryPackagingId,
		transportPackagingId: batteryInfo.transportPackagingId,
	};
}

export function getBatteryStatusInfo($t, batteryConditions) {
	return [
		// noDamage
		{
			status: "ok",
			title: get((batteryConditions || []).find(condition => condition.id === "cf305399-4009-40f6-a336-71a09dec2643"), 'name', ''),
			id: "cf305399-4009-40f6-a336-71a09dec2643",
			attachmentRequired: false,
			icon: require('@/assets/img/svg-icons/battery-ok.svg'),
			iconName: "battery-ok",
			description: $t('addBattery.condition.status.description.noDamage'),
		},
		// damaged
		{
			status: "damaged",
			title: get((batteryConditions || []).find(condition => condition.id === "dbd7049b-65c2-4fd6-bff6-abcf6c3d45cb"), 'name', ''),
			id: "dbd7049b-65c2-4fd6-bff6-abcf6c3d45cb",
			attachmentRequired: true,
			icon: require('@/assets/img/svg-icons/battery-damaged.svg'),
			iconName: "battery-damaged",
			description: $t('addBattery.condition.status.description.damaged'),
		},
		// critical
		{
			status: "critical",
			title: get((batteryConditions || []).find(condition => condition.id === "ca789891-6817-4331-9f82-02223283d4e6"), 'name', ''),
			id: "ca789891-6817-4331-9f82-02223283d4e6",
			attachmentRequired: true,
			icon: require('@/assets/img/svg-icons/battery-critical.svg'),
			iconName: "battery-critical",
			description: $t('addBattery.condition.status.description.critical'),
		}
	];
}

function validate(mixinReference, prop) {
	// If the input field has an error, and the value changes, re-validate the form
	if (mixinReference.batteryFormFieldErrors[prop]) {
		// Validate the form after the values are set
		setTimeout(() => mixinReference.validateBatteryFormModel(mixinReference, mixinReference.batteryFormModel, mixinReference.state.currentEditBatteryStep));
	}
}

function resetFormDependents(mixinReference, key, newValue, includeModel) {
	if (mixinReference.batteryFormModel[key] === newValue) {
		return;
	}

	setTimeout(() => {
		// Re-init the field below that depend on this value
		if (includeModel) {
			mixinReference.batteryFormModel.model = null;
		}
		mixinReference.batteryFormModel.partNumber = null;
		mixinReference.batteryFormModel.batteryDescriptionId = null;
		mixinReference.batteryFormModel.constructionYear = null;
		mixinReference.batteryFormModel.nettoWeight = null;
		mixinReference.batteryFormModel.width = null;
		mixinReference.batteryFormModel.height = null;
		mixinReference.batteryFormModel.depth = null;
		mixinReference.batteryFormModel.chemicalFamilyTypeId = null;
		mixinReference.batteryFormModel.serialNumber = null;
		mixinReference.batteryFormModel.internalReference = null;
		mixinReference.batteryFormModel.attachments = [];
	});
}

/**
 * Combines the form fields for both the addBattery sidebar first step in the pup (create) and for the cp (edit)
 * @param mixinReference
 * @return {{fields: *[]}[]|*[]}
 */
export function getBatteryTypeFields(mixinReference) {
	const activeBrand = mixinReference.brands.find((brand) => brand.id === mixinReference.brand) || {};
	const isSerialNumberRequired = get(activeBrand, "isSerialNumberRequired", false);

	return [
		{
			fields: [
				{
					inputProps: {
						id: 'battery-brand',
						options: mixinReference.brands,
						placeholder: mixinReference.$t('addBattery.brand'),
						type: 'select',
					},
					label: mixinReference.$t('addBattery.brand'),
					modelKey: 'brand',
					required: true,
					onValueChanged: (value) => {
						validate(mixinReference, 'brand');
						mixinReference.$store.dispatch('getBatteryModels', {
							businessRelationId: mixinReference.businessRelationId,
							brandId: value,
						});
						resetFormDependents(mixinReference, 'brand', value, true);
					},
				},
				{
					disabled:
						mixinReference.batteryFormModel.batteryNotListed ||
						!mixinReference.batteryFormModel.brand ||
						mixinReference.batteryModels.length <= 0,
					inputProps: {
						id: 'battery-model',
						options: mixinReference.batteryModels.map(model => ({
							value: get(model, 'batteryModelId'),
							name: get(model, 'name'),
						})),
						placeholder: mixinReference.$t('addBattery.model'),
						type: 'select',
					},
					icon: {
						name: 'info',
						pos: 'right',
						width: '20px',
						height: '20px',
						tooltip: {
							content: mixinReference.$t('addBattery.missingModel'),
							placement: 'top-center',
						},
					},
					label: mixinReference.$t('addBattery.model'),
					modelKey: 'model',
					required: true,
					onValueChanged: value => {
						validate(mixinReference, 'model');
						resetFormDependents(mixinReference, 'model', value, false);
					},
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					inputProps: {
						id: 'battery-part-number',
						options: (() => {
							if (!mixinReference.variationsForModel) {
								return [];
							}

							const sortedPartNumbers = sortBy(mixinReference.variationsForModel.batteryDescriptions, ['partNumber', 'constructionYear']);

							return sortedPartNumbers.length
								? sortedPartNumbers.map(batteryDescription => ({
										value: batteryDescription.partNumber,
										name: `${batteryDescription.partNumber} - ${batteryDescription.constructionYear}`,
								  }))
								: [];
						})(),
						placeholder: mixinReference.$t('addBattery.partNumber'),
						type: 'select',
					},
					label: mixinReference.$t('addBattery.partNumber'),
					modelKey: 'partNumber',
					required: true,
					onValueChanged: partNumber => {
						validate(mixinReference, 'partNumber');
						const model = mixinReference.variationsForModel;
						if (model && model.batteryDescriptions.length) {
							const variation = model.batteryDescriptions.find(v => v.partNumber === partNumber);

							if (!variation) {
								logger.error(
									new RecursiveError('Failed to find battery by part number', null, {
										variationsForModel: model,
										partNumber,
									})
								);
								mixinReference.$store.dispatch('setMessage', {
									text: mixinReference.$t('error.default'),
									type: MessageTypes.ERROR,
								});

								return;
							}

							setTimeout(() => {
								mixinReference.batteryFormModel.batteryDescriptionId = variation.id;
								mixinReference.batteryFormModel.constructionYear = variation.constructionYear;
								mixinReference.batteryFormModel.nettoWeight = variation.nettoWeight;
								mixinReference.batteryFormModel.width = variation.width;
								mixinReference.batteryFormModel.height = variation.height;
								mixinReference.batteryFormModel.depth = variation.depth;
								mixinReference.batteryFormModel.chemicalFamilyTypeId = get(model, 'chemicalFamily.id');
							});
						}
					},
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					disabled: true,
					cols: '6',
					inputProps: {
						id: 'battery-year',
						options: {
							min: 1900,
							max: new Date().getFullYear(),
						},
						type: 'number',
					},
					label: mixinReference.$t('addBattery.year'),
					modelKey: 'constructionYear',
					required: true,
					onValueChanged: () => validate(mixinReference, 'constructionYear'),
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					disabled: true,
					cols: '6',
					inputProps: {
						id: 'battery-weight',
						placeholder: '1',
						options: {
							min: 1,
						},
						type: 'number',
					},
					label: `${mixinReference.$t('addBattery.weight')} (${mixinReference.$t('common.kg')})`,
					modelKey: 'nettoWeight',
					required: true,
					onValueChanged: () => validate(mixinReference, 'nettoWeight'),
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					disabled: true,
					cols: '4',
					inputProps: {
						id: 'battery-dimensions-w',
						placeholder: '1',
						options: {
							min: 1,
						},
						type: 'number',
					},
					label: `${mixinReference.$t('addBattery.width')} (${mixinReference.$t('common.mm')})`,
					modelKey: 'width',
					required: true,
					onValueChanged: () => validate(mixinReference, 'width'),
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					disabled: true,
					cols: '4',
					inputProps: {
						id: 'battery-dimensions-h',
						placeholder: '1',
						options: {
							min: 1,
						},
						type: 'number',
					},
					label: `${mixinReference.$t('addBattery.height')} (${mixinReference.$t('common.mm')})`,
					modelKey: 'height',
					required: true,
					onValueChanged: () => validate(mixinReference, 'height'),
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					disabled: true,
					cols: '4',
					inputProps: {
						id: 'battery-dimensions-d',
						placeholder: '1',
						options: {
							min: 1,
						},
						type: 'number',
					},
					label: `${mixinReference.$t('addBattery.depth')} (${mixinReference.$t('common.mm')})`,
					modelKey: 'depth',
					required: true,
					onValueChanged: () => validate(mixinReference, 'depth'),
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					disabled: true,
					inputProps: {
						id: 'chemicalFamilyTypeId',
						options: (mixinReference.chemicalFamilies || []).map(chemicalFamily => ({
							value: chemicalFamily.id,
							name: chemicalFamily.name,
						})),
						placeholder: mixinReference.$t('addBattery.chemicalFamily'),
						type: 'select',
					},
					label: mixinReference.$t('addBattery.chemicalFamily'),
					modelKey: 'chemicalFamilyTypeId',
					required: true,
					onValueChanged: chemicalFamilyTypeId => {
						validate(mixinReference, 'chemicalFamilyTypeId');
						mixinReference.batteryFormModel.chemicalFamilyTypeId = chemicalFamilyTypeId;
					},
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					inputProps: {
						id: 'battery-serial',
						type: 'text',
					},
					label: mixinReference.$t('addBattery.serialNumber'),
					modelKey: 'serialNumber',
					required: isSerialNumberRequired,
					onValueChanged: () => validate(mixinReference, 'serialNumber'),
				},
				{
					show: mixinReference.batteryFormModel.model || false,
					inputProps: {
						id: 'battery-internal-reference',
						type: 'text',
					},
					label: mixinReference.$t('addBattery.internalReference'),
					modelKey: 'internalReference',
					required: false,
					onValueChanged: () => validate(mixinReference, 'internalReference'),
				},
			],
		},
	];
}

/**
 * Combines the form fields for both the addBattery sidebar second step in the pup (create) and for the cp (edit)
 * @param mixinReference
 * @return {{fields: *[]}[]|*[]}
 */
export function getBatteryConditionFields(mixinReference) {
	const undamagedAllowed = get(mixinReference, 'contract.undamagedAllowed', true);
	const damagedAllowed = get(mixinReference, 'contract.damagedAllowed', true);
	const criticalAllowed = get(mixinReference, 'contract.criticalAllowed', true);

	const hasAlert = !undamagedAllowed || !damagedAllowed || !criticalAllowed;

	return [
		{
			fields: [
				{
					inputProps: {
						id: 'battery-condition',
						type: 'battery-condition',
						options: getBatteryStatusInfo(mixinReference.$t, mixinReference.batteryConditions).map(status => {
							const isDisabled = (status.status === "ok" && !undamagedAllowed) || (status.status === "damaged" && !damagedAllowed) || (status.status === "critical" && !criticalAllowed);

							return {
								value: status.id,
								name: status.status,
								icon: status.icon,
								title: status.title,
								description: status.description,
								disabled: isDisabled,
							}
						}),
					},
					alert: hasAlert ? {
						icon: 'warning',
						message: mixinReference.$t('addBattery.batteryConditionNotAllowed'),
					}: null,
					label: mixinReference.$t('addBattery.batteryStatus'),
					value: mixinReference.batteryFormModel.batteryConditionId,
					modelKey: 'batteryConditionId',
					required: true,
					onValueChanged: () => validate(mixinReference, 'batteryConditionId'),
				},
				{
					show: (mixinReference.activeBatteryStatus && mixinReference.activeBatteryStatus.attachmentRequired) || false,
					required: (mixinReference.activeBatteryStatus && mixinReference.activeBatteryStatus.attachmentRequired) || false,
					inputProps: {
						id: 'battery-damaged-description',
						type: 'textarea',
					},
					label: mixinReference.$t('addBattery.damagedDescription'),
					modelKey: 'damagedDescription',
					columnLayout: true,
					onValueChanged: () => validate(mixinReference, 'damagedDescription'),
				},
			],
		},
	];
}

/**
 * Combines the form fields for both the addBattery sidebar third step in the pup (create) and for the cp (edit)
 * @param mixinReference
 * @return {{fields: *[]}[]|*[]}
 */
export function getBatteryPackagingFields(mixinReference) {
	const batteryPackagings = mixinReference.$store.getters.getBatteryPackagings(mixinReference.businessRelationId, mixinReference.batteryFormModel.brand);
	const allowCustomPackaging = mixinReference.$store.getters.getAllowCustomPackaging(mixinReference.$store.getters.locationId, mixinReference.batteryFormModel.brand);
	const packagingOption = mixinReference.$store.getters.getPackagingOption(mixinReference.$store.getters.locationId, mixinReference.batteryFormModel.brand, mixinReference.batteryFormModel.batteryConditionId);

	return [
		{
			fields: [
				{
					disabled: packagingOption && packagingOption === "COPackagingRequired",
					inputProps: {
						id: 'battery-packaging-method',
						name: 'battery-packaging-method',
						type: 'radio',
						options: batteryPackagings
							? batteryPackagings.map(packaging => ({
								...packaging,
								value: packaging.id,
							}))
							: [],
					},
					alert: packagingOption && packagingOption === "COPackagingRequired" ? {
						icon: 'info',
						message: mixinReference.$t('addBattery.COPackagingRequired'),
					} : null,
					modelKey: 'batteryPackagingId',
					label: mixinReference.$t('common.batteryPackaging'),
					subLabel: mixinReference.$t('common.batteryPackaging.description'),
					value: mixinReference.batteryFormModel.batteryPackagingId || null,
				},
				{
					show: !!(packagingOption && packagingOption !== "COPackagingRequired" && allowCustomPackaging),
					inputProps: {
						id: 'custom-battery-packaging',
						name: 'battery-packaging-method',
						type: 'radio',
						options: [
							{
								value: true,
								name: mixinReference.$t('addBattery.ownPackaging.description'),
							},
						],
					},
					modelKey: 'customBatteryPackaging',
					value: !!mixinReference.batteryFormModel.customBatteryPackaging,
				},
				{
					show: !!mixinReference.batteryFormModel.customBatteryPackaging,
					required: !!mixinReference.batteryFormModel.customBatteryPackaging,
					label: mixinReference.$t("addBattery.nonStandardPackaging.packagingName"),
					inputProps: {
						id: "battery-packaging-name",
						name: "battery-packaging-name",
						type: "text",
					},
					modelKey: 'packagingName',
					value: get(mixinReference.batteryFormModel, "packaging.name") || "",
					onValueChanged: (newName) => {
						mixinReference.updateBatteryFormModel({
							packaging: {
								...mixinReference.batteryFormModel.packaging,
								name: newName || "",
							},
						});
					},
				},
				{
					show: !!mixinReference.batteryFormModel.customBatteryPackaging,
					required: !!mixinReference.batteryFormModel.customBatteryPackaging,
					label: withKgUnit(mixinReference, "addBattery.nonStandardPackaging.packagingWeight"),
					inputProps: {
						id: "battery-packaging-weight",
						name: "battery-packaging-weight",
						type: "number",
						placeholder: "1",
					},
					modelKey: 'packagingWeight',
					value: get(mixinReference.batteryFormModel, "packaging.weight") || 1,
					onValueChanged: (newWeight) => {
						mixinReference.updateBatteryFormModel({
							packaging: {
								...mixinReference.batteryFormModel.packaging,
								weight: newWeight || 1,
							},
						});
					},
				},
				{
					show: !!mixinReference.batteryFormModel.customBatteryPackaging,
					required: !!mixinReference.batteryFormModel.customBatteryPackaging,
					cols: "4",
					inputProps: {
						id: "battery-dimensions-w",
						placeholder: "1",
						options: {
							min: 1,
						},
						type: "number",
					},
					modelKey: 'packagingWidth',
					label: withMmUnit(mixinReference, "addBattery.width") || 1,
					onValueChanged: (newWidth) => {
						mixinReference.updateBatteryFormModel({
							packaging: {
								...mixinReference.batteryFormModel.packaging,
								width: newWidth || 1,
							},
						});
					},
				},
				{
					show: !!mixinReference.batteryFormModel.customBatteryPackaging,
					required: !!mixinReference.batteryFormModel.customBatteryPackaging,
					cols: "4",
					inputProps: {
						id: "battery-dimensions-h",
						placeholder: "1",
						options: {
							min: 1,
						},
						type: "number",
					},
					modelKey: 'packagingHeight',
					label: withMmUnit(mixinReference, "addBattery.height") || 1,
					onValueChanged: (newHeight) => {
						mixinReference.updateBatteryFormModel({
							packaging: {
								...mixinReference.batteryFormModel.packaging,
								height: newHeight || 1,
							},
						});
					},
				},
				{
					show: !!mixinReference.batteryFormModel.customBatteryPackaging || false,
					required: !!mixinReference.batteryFormModel.customBatteryPackaging,
					cols: "4",
					inputProps: {
						id: "battery-dimensions-d",
						placeholder: "1",
						options: {
							min: 1,
						},
						type: "number",
					},
					modelKey: 'packagingDepth',
					label: withMmUnit(mixinReference, "addBattery.depth") || 1,
					onValueChanged: (newDepth) => {
						mixinReference.updateBatteryFormModel({
							packaging: {
								...mixinReference.batteryFormModel.packaging,
								depth: newDepth || 1,
							},
						});
					},
				},
				{
					show: !!mixinReference.batteryFormModel.customBatteryPackaging || false,
					required: false,
					modelKey: 'suitedForTransport',
					inputProps: {
						id: "battery-packaging-suited-for-transport",
						type: "checkbox",
						options: [
							{
								value: true,
								name: `${mixinReference.$t("addBattery.suitedForTransport")}`,
							},
						],
					},
					onValueChanged: (newSuitedForTransport) => {
						mixinReference.updateBatteryFormModel({
							packaging: {
								...mixinReference.batteryFormModel.packaging,
								suitedForTransport: newSuitedForTransport || 1,
							},
						});
					},
				},
				{
					show: packagingOption && packagingOption !== "OwnPackagingRequired",
					inputProps: {
						id: 'no-battery-packaging',
						name: 'battery-packaging-method',
						type: 'radio',
						options: [
							{
								value: true,
								name: mixinReference.$t('addBattery.unpackaged.description'),
							},
						],
					},
					modelKey: 'noBatteryPackaging',
					value: !!mixinReference.batteryFormModel.noBatteryPackaging,
				},
			],
		},
	];
}

/**
 * Uploads an attachment to the locutus api and attaches it to the current battery
 * @param mixinReference
 * @param files the files array from the input html5 control
 * @param type "statusReport" | "photo"
 */
export async function prepareAttachments(mixinReference, files, type) {
	mixinReference.isUploadingAttachments = true;

	const promises = Array.from(files || []).map(file => new Promise((resolve, reject) => {
			if (!file) {
				reject('This extension isn’t valid');
				return;
			}

			if (ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE[type] && !ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE[type].mimeTypes.includes(file.type)) {
				reject(mixinReference.$t('message.attachment.upload.wrongType', {
					exts: ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE[type].allowedExtensions,
				}));
				return;
			}

			const reader = new FileReader();
			reader.addEventListener(
				'loadend',
				async () => {
					// remove the first part of the base64 data url, so we only keep the base64 data
					const fileBase64 = reader.result.substring(reader.result.indexOf(',') + 1);

					const fileInfo = {
						content: fileBase64,
						fileName: file.name,
						contentType: file.type || 'application/octet-stream',
						typeId: FileTypes.Document, // Document type
					};

					// https://softwareengineering.stackexchange.com/posts/288673/revisions
					const fileSize = (fileBase64.length - 814) / 1.37;

					console.log(fileSize)


					if (fileSize > 6000000) {
						reject(mixinReference.$t('message.attachment.upload.tooLarge'));
						return;
					}

					try {
						const response = await createAttachment(fileInfo, type);
						mixinReference.batteryFormModel.attachments.push(response);
						if (type === 'photo') {
							mixinReference.batteryFormModel.photoAdded = true;
							mixinReference.batteryFormFieldErrors.photoAdded = null;
						} else if (type === 'statusReport') {
							mixinReference.batteryFormModel.statusReportAdded = true;
							mixinReference.batteryFormFieldErrors.statusReportAdded = null;
						}
						resolve({ data: { success: true } });
					} catch (error) {
						reject(mixinReference.$t('message.attachment.upload.tooLarge'));
					}
				},
				false
			);

			return reader.readAsDataURL(file);
		})
	).filter(promise => !!promise);

	const responses = await Promise.allSettled(promises);

	const errors = responses.filter(response => response.status === 'rejected');
	const successes = responses.filter(response => response.status === 'fulfilled').map(response => response.value);

	if (errors && errors.length) {
		logAndToastError(mixinReference.$store.dispatch, errors[0].reason || 'Failed to upload attachment', errors[0].reason, {
			files,
			type,
		});

		mixinReference.isUploadingAttachments = false;
		return;
	}

	if (successes && successes.length) {
		mixinReference.$store.dispatch('setMessage', {
			text: mixinReference.$tc('message.attachment.upload.success', successes.length),
			type: MessageTypes.SUCCESS,
		});

		mixinReference.isUploadingAttachments = false;
	}
}

export function removeAttachment(mixinReference, attachmentId) {
	const toRemoveAttachmentIndex = mixinReference.batteryFormModel.attachments.indexOf(
		mixinReference.batteryFormModel.attachments.find(attachment => attachment.attachmentId === attachmentId)
	);
	mixinReference.batteryFormModel.attachments.splice(toRemoveAttachmentIndex, 1);

	const fileAmounts = mixinReference.batteryFormModel.attachments.reduce(
		(acc, curr) => {
			if (curr.type === 'photo') {
				acc.photos = [...acc.photos, curr];
			} else {
				acc.statusReports = [...acc.statusReports, curr];
			}

			return acc;
		},
		{
			photos: [],
			statusReports: [],
		}
	);

	if (fileAmounts.photos.length === 0) {
		mixinReference.batteryFormModel.photoAdded = null;
	} else if (fileAmounts.statusReports.length === 0) {
		mixinReference.batteryFormModel.statusReportAdded = null;
	}
}

export function validateBatteryTypeProperties(model, batteryFormFieldErrors, $t, reference) {
	let valid = true;
	const activeBrand = reference.brands.find((brand) => brand.id === reference.brand) || {};
	const isSerialNumberRequired = get(activeBrand, "isSerialNumberRequired", false);

	if (!model.brand) {
		batteryFormFieldErrors.brand = $t('error.required', [$t('addBattery.brand')]);
		valid = false;
	}

	if (!model.model) {
		batteryFormFieldErrors.model = $t('error.required', [$t('addBattery.model')]);
		valid = false;
	}

	if (!model.partNumber) {
		batteryFormFieldErrors.partNumber = $t('error.required', [$t('addBattery.partNumber')]);
		valid = false;
	}

	if (!model.chemicalFamilyTypeId) {
		batteryFormFieldErrors.chemicalFamilyTypeId = $t('error.required', [$t('addBattery.chemicalFamily')]);
		valid = false;
	}

	if (!model.width) {
		batteryFormFieldErrors.width = $t('error.required', [$t('addBattery.width')]);
		valid = false;
	}

	if (!model.height) {
		batteryFormFieldErrors.height = $t('error.required', [$t('addBattery.height')]);
		valid = false;
	}

	if (!model.depth) {
		batteryFormFieldErrors.depth = $t('error.required', [$t('addBattery.depth')]);
		valid = false;
	}

	if (!model.serialNumber && isSerialNumberRequired) {
		batteryFormFieldErrors.serialNumber = $t('error.required', [$t('addBattery.serialNumber')]);
		valid = false;
	}

	if (!model.batteryDescriptionId && !model.constructionYear) {
		batteryFormFieldErrors.constructionYear = $t('error.required', [$t('addBattery.year')]);
		valid = false;
	}

	if (!model.batteryDescriptionId && (!model.nettoWeight || model.nettoWeight === 0)) {
		batteryFormFieldErrors.nettoWeight = $t('error.required', [$t('addBattery.weight')]);
		valid = false;
	}

	return valid;
}

export function validateBatteryConditionProperties(model, batteryFormFieldErrors, $t, skipImageAttachments, skipBmsAttachment, mixinReference) {
	let valid = true;
	const activeBatteryStatus = mixinReference.activeBatteryStatus;

	if (!activeBatteryStatus) {
		batteryFormFieldErrors.batteryConditionId = $t('error.required', [$t('common.conditionOfBattery')]);
		valid = false;

		return valid;
	}

	const isCritical = activeBatteryStatus.status === "critical";

	if (isCritical && !model.photoAdded && !skipImageAttachments) {
		batteryFormFieldErrors.photoAdded = true;
		valid = false;
	}

	if (isCritical && !model.damagedDescription) {
		batteryFormFieldErrors.damagedDescription = $t('error.required', [$t('addBattery.damagedDescription.short')]);
		valid = false;
	}

	if (isCritical && !model.statusReportAdded && !skipBmsAttachment) {
		batteryFormFieldErrors.statusReportAdded = true;
		valid = false;
	}

	return valid;
}

export function validateBatteryPackagingProperties(model, batteryFormFieldErrors, $t) {
	let valid = true;

	if (model.batteryPackagingId === null && model.customBatteryPackaging === null && model.noBatteryPackaging === false) {
		batteryFormFieldErrors.batteryPackagingId = $t('error.required', [$t('common.packagingMethod')]);
		valid = false;
	}

	// Disabled on request of Bebat: https://studiohyperdrive.atlassian.net/browse/RENEO-431
	if (!model.batteryPackagingId) {
		if (model.customBatteryPackaging && (!model.packaging || !model.packaging.name)) {
			batteryFormFieldErrors.packagingName = $t("error.required", [$t("addBattery.nonStandardPackaging.packagingName")]);
			valid = false;
		}

		if (model.customBatteryPackaging && !model.packaging.weight) {
			batteryFormFieldErrors.packagingWeight = $t("error.required", [$t("addBattery.weight")]);
			valid = false;
		}

		if (model.customBatteryPackaging && !model.packaging.width) {
			batteryFormFieldErrors.packagingWidth = $t("error.required", [$t("addBattery.width")]);
			valid = false;
		}

		if (model.customBatteryPackaging && !model.packaging.height) {
			batteryFormFieldErrors.packagingHeight = $t("error.required", [$t("addBattery.height")]);
			valid = false;
		}

		if (model.customBatteryPackaging && !model.packaging.depth) {
			batteryFormFieldErrors.packagingDepth = $t("error.required", [$t("addBattery.depth")]);
			valid = false;
		}
	}

	return valid;
}
