import get from 'lodash/get';

import RnIcon from '@/components/icon/icon.vue';
import RnButton from '@/components/button/button.vue';

export default {
	name: 'rn-card',

	components: {
		RnIcon,
		RnButton
	},

	data() {
		return {
			value: 0,
			disabled: true,
		}
	},

	props: {
		data: {
			type: Object,
			required: true,
			default: () => ({}),
		}
	},

	computed: {
		id() {
			return get(this.data, "id", "");
		},

		name() {
			return get(this.data, "name", "");
		},

		icon() {
			return this.data.icon ? get(this.data, "icon.name", "") : "custom-package";
		}
	},

	methods: {
		add() {
			this.value += 1;

			if (this.value > 0) {
				this.disabled = false
			}

			return this.$emit("change", {
				value: this.value,
				id: this.id
			});
		},

		remove() {
			if (this.value <= 1) {
				this.disabled = true
			}

			this.value >= 1 ? this.value -= 1 : this.value = 0;

			return this.$emit("change", {
				value: this.value,
				id: this.id
			});
		}
	}
}
