import RnIcon from '@/components/icon/icon.vue';
import { fieldMixin } from '@/mixins';

export default {
	name: 'cs-field-multi-select',
	components: { RnIcon },
	mixins: [fieldMixin],
	props: {
		defaultChecked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
    return {
      isOpen: false,
			values: this.defaultChecked ? this.options.map(option => option.value) : [],
    }
  },
	computed: {
		selectedCount() {
			return this.values.length;
		},
		visibleSelectedCount() {
			return this.values.length > 9 ? "9+" : this.values.length;
		}
	},
	watch: {
		value(newValues) {
			this.values = newValues;
		},
    values(newValues) {
			this.$emit('update:value', newValues);
    },
  },
	methods: {
		setIsOpen(state) {
			this.isOpen = state;
		},
	},
};
