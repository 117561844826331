import { Sortable, Plugins } from "@shopify/draggable";

export default {
	name: 'rn-dropzone-manager',

	props: {
		batteries: {
			type: Array,
			default: () => ([]),
		},
	},

	data() {
		return {
			sortable: null,
		}
	},

	mounted() {
		this.initDropzones();
	},

	methods: {
		initDropzones() {
			const zones = this.$el.querySelectorAll('.block-zones');

			this.sortable = new Sortable(zones, {
				draggable: ".block--isDraggable",
				handle: ".block--handle",
				mirror: {
					constrainDimensions: true
				},
				sortAnimation: {
					duration: 200,
					easingFunction: 'ease-in-out',
				},
				plugins: [
					Plugins.SortAnimation,
				]
			});

			this.getDefaultZoneItems();

			this.sortable.on('drag:over:container', (e) => {
				const { packagingOption, suitedForTransport } = JSON.parse(e.data.originalSource.dataset.battery);
				const { packaging: noPackaging } = e.data.overContainer.dataset;

				if (packagingOption === "OwnPackagingRequired" && !suitedForTransport && noPackaging) {
					e.cancel();
				}
			});

			this.sortable.on('sortable:stop', (e) => {
				const dropzoneId = e.data.newContainer.dataset.id;
				const draggableId = JSON.parse(e.data.dragEvent.data.originalSource.dataset.battery).id;

				this.$emit("sort", {
					dropzoneId,
					draggableId
				});

				this.getDefaultZoneItems();
				this.getEmptyZoneItems();

				this.sortable.containers.forEach((zone) => {
					// setTimeout to prevent bug that childcount doesn't update correctly
					setTimeout(() => {
						if (zone.childElementCount > 1) {
							zone.classList.add("is-active");
						} else {
							if (zone.classList.contains("is-active")) {
								zone.classList.remove("is-active");
							}
						}
					}, 1);
				})
			});
		},

		getDefaultZoneItems() {
			const defaultItemsInZone = this.sortable.getSortableElementsForContainer(this.sortable.containers[this.sortable.containers.length - 1]).length;

			this.$emit("defaultZone", defaultItemsInZone);
		},

		getEmptyZoneItems() {
			const containers = this.sortable.containers || [];
			const isZoneEmpty = containers.filter((container) => container.dataset.id && container.children.length <= 1);

			this.$emit("emptyZones", isZoneEmpty.length > 0);
		}
	},
}
