import get from 'lodash/get';

import store from '@/store';

export default function(to, from, next) {
	const user = store.getters.user;
	const language = get(to, 'params.language');
	if (user) {
		// User is authenticated, we can continue to the requested route
		next();
	} else {
		// User needs to login first
		next({
			name: 'Login',
			params: { language },
		});
	}
}
