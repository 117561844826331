import get from 'lodash/get';
import formHelpersMixin from './helpers';

const transportPackagingFormKeys = {
	packagings: [],
	customPackaging: false,
	name: '',
	width: 0,
	height: 0,
	depth: 0,
	weight: 0,
};

const transportPackagingFormMixin = {
	mixins: [formHelpersMixin],
	data() {
		return {
			transportPackagingFormId: 'transport-packaging-form',
			transportPackagingFormModel: {
				...transportPackagingFormKeys,
			},
			transportPackagingFormFieldErrors: {
				...transportPackagingFormKeys,
			},
		};
	},
	computed: {
		transportPackaging() {
			return this.$store.getters.getTransportPackagings(this.businessRelationId, this.selectedBrandId);
		},
		selectedBrandId() {
			return get(this, 'state.batteries[0].brandId', '');
		},
		allowCustomPackaging() {
			return get(this, 'state.allowCustomPackaging', false);
		},
		transportPackagingFieldGroups() {
			const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, this.selectedBrandId);

			return [
				{
					fields: [
						{
							inputProps: {
								id: 'transport-packaging-method',
								name: 'transport-packaging-method',
								options: transportPackagings
									? transportPackagings.map(packaging => ({
										...packaging,
										value: packaging.id,
									}))
									: [],
								type: 'amount',
							},
							modelKey: 'packagings',
						},
						{
							show: this.allowCustomPackaging,
							inputProps: {
								id: 'custom-transport-packaging-method',
								name: 'custom-transport-packaging-method',
								type: 'checkbox',
								options: [{ value: true, name: this.$t('stepForm.addOwnPackaging') }],
							},
							modelKey: 'customPackaging',
						},
						{
							show: !!this.transportPackagingFormModel.customPackaging,
							inputProps: {
								id: 'packaging-name',
								type: 'text',
							},
							label: this.$t('addPackaging.name'),
							modelKey: 'name',
							required: true,
						},
						{
							show: !!this.transportPackagingFormModel.customPackaging,
							cols: '4',
							inputProps: {
								id: 'packaging-dimensions-w',
								placeholder: '1',
								options: {
									min: 1,
								},
								type: 'number',
							},
							label: `${this.$t('addPackaging.width')} (${this.$t('common.mm')})`,
							modelKey: 'width',
							required: true,
						},
						{
							show: !!this.transportPackagingFormModel.customPackaging,
							cols: '4',
							inputProps: {
								id: 'packaging-dimensions-h',
								placeholder: '1',
								options: {
									min: 1,
								},
								type: 'number',
							},
							label: `${this.$t('addPackaging.height')} (${this.$t('common.mm')})`,
							modelKey: 'height',
							required: true,
						},
						{
							show: !!this.transportPackagingFormModel.customPackaging,
							cols: '4',
							inputProps: {
								id: 'packaging-dimensions-d',
								placeholder: '1',
								options: {
									min: 1,
								},
								type: 'number',
							},
							label: `${this.$t('addPackaging.depth')} (${this.$t('common.mm')})`,
							modelKey: 'depth',
							required: true,
						},
						{
							show: !!this.transportPackagingFormModel.customPackaging,
							inputProps: {
								id: 'packaging-dimensions-weight',
								placeholder: '1',
								options: {
									min: 1,
								},
								type: 'number',
							},
							label: `${this.$t('addPackaging.weight')} (${this.$t('common.kg')})`,
							modelKey: 'weight',
							required: true,
						},
					],
				},
			];
		},
		transportPackagingForm() {
			return {
				id: this.transportPackagingFormId,
				title: this.$t('addPackaging.title'),
				model: this.transportPackagingFormModel,
				fieldGroups: this.transportPackagingFieldGroups,
				modelUpdateHandler: this.updateTransportPackagingFormModel,
				fieldErrors: this.transportPackagingFormFieldErrors,
			};
		},
	},
	watch: {
		selectedBatteries() {
			if (this.selectedBrandId) {
				this.$store.dispatch('getTransportPackagings', {
					businessRelationId: this.businessRelationId,
					brandId: this.selectedBrandId,
				});
			}
		},
	},
	methods: {
		resetAddPackagingFormModel() {
			this.transportPackagingFormModel = {
				...transportPackagingFormKeys,
			};
		},
		updateTransportPackagingFormModel(newModel) {
			this.transportPackagingFormModel = {
				...this.transportPackagingFormModel,
				...newModel,
			};
		},
		clearTransportPackagingFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'addPackaging' });
		},
		clearTransportPackagingFormFieldErrors() {
			this.transportPackagingFormFieldErrors = {
				...transportPackagingFormKeys,
			};
		},
		validateTransportPackagingFormModel(model, checkCustomPackaging = false) {
			this.clearTransportPackagingFormError();
			this.clearTransportPackagingFormFieldErrors();

			let valid = true;

			if (checkCustomPackaging && model.customPackaging) {
				if (!model.name) {
					this.transportPackagingFormFieldErrors.name = this.$t("error.required", [this.$t("addPackaging.name")]);
					valid = false;
				}

				if (!model.width) {
					this.transportPackagingFormFieldErrors.width = this.$t("error.required", [this.$t("addPackaging.width")]);
					valid = false;
				}

				if (!model.height) {
					this.transportPackagingFormFieldErrors.height = this.$t("error.required", [this.$t("addPackaging.height")]);
					valid = false;
				}

				if (!model.depth) {
					this.transportPackagingFormFieldErrors.depth = this.$t("error.required", [this.$t("addPackaging.depth")]);
					valid = false;
				}

				if (!model.weight) {
					this.transportPackagingFormFieldErrors.weight = this.$t("error.required", [this.$t("addPackaging.weight")]);
					valid = false;
				}

				return valid;
			}

			if (model.packagings.length <= 0) {
				this.transportPackagingFormFieldErrors.packagings = this.$t('error.required', [this.$t('common.packagingMethod')]);
				valid = false;
			}

			return valid;
		},
	},
};

export default transportPackagingFormMixin;
