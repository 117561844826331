export const generateFilterString = (body) => {
	if (!body) {
		return "";
	}

	const searchParams = new URLSearchParams(window.location.search);
	const filterExpressions = [];
	const specificFilterExpressions = [];

	if (body.tableHeaders) {
		for (const [key, param] of searchParams.entries()) {
			const tableHeader = body.tableHeaders.find((header) => header.name === key);

			if (tableHeader) {
				const properties = tableHeader.properties;
				for (let i = 0; i < properties.length; i++) {
					const property = properties[i];

					if (tableHeader.filterOperator === "in") {
						const values = param.split(",");
						if (!values || !values.length || values.some((value) => !value)) {
							continue;
						}

						const filterExpression = `${tableHeader.path ? tableHeader.path : ''}${property.name} in (${values.map((value) => `'${value}'`).join(", ")})`;
						filterExpressions.push(filterExpression);

						continue;
					}

					for (let j = 0; j < param.split(",").length; j++) {
						const value = param.split(",")[j];

						if (!property.name || !value) {
							continue;
						}

						if (property.systemType === "string" && property.name !== "Id") {
							const filterExpression = `${tableHeader.path ? tableHeader.path : ''}${property.name} ct '${value}'`;

							filterExpressions.push(filterExpression);
						}

						if (property.systemType === "DateTime" && property.name !== "Id") {
							const [from, to] = value.split("&");
							if (from) {
								const filterExpression = `${property.name} ge '${from}'`;

								filterExpressions.push(filterExpression);
							}

							if (to) {
								const filterExpression = `${property.name} le '${to}'`;

								filterExpressions.push(filterExpression);
							}
						}

						if (property.type === "number" && property.name !== "Id") {
							const filterExpression = `${tableHeader.path ? tableHeader.path : tableHeader.name}${tableHeader.path ? property.name : ''} eq ${value}`;

							filterExpressions.push(filterExpression);
						}
					}
				}
			}
		}
	}

	if (body.collectionOrderId) {
		filterExpressions.push(`id eq ${body.collectionOrderId}`);
	}

	if (body.statusIds) {
		for (let i = 0; i < body.statusIds.length; i++) {
			const status = body.statusIds[i];
			specificFilterExpressions.push(`expand(Status).Name eq ${status}`);
		}
	}

	const filterString = !!filterExpressions.length ? filterExpressions.join(' and ') : "";
	const specificFilterString = !!specificFilterExpressions.length ? specificFilterExpressions.join(' or ') : "";

	if (specificFilterString) {
		return `${filterString} and ${specificFilterString}`;
	}

	return filterString;
};
