// General field mixin
// ---
//
// ------------------------------------------------------------------------- /
import formHelpersMixin from './forms/helpers';

const fieldMixin = {
	mixins: [formHelpersMixin],
	data: () => ({
		lastValue: undefined,
	}),
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: false,
		},
		options: {
			type: [Array, Object],
			default: () => ({
				min: null,
				max: null,
			}),
		},
		placeholder: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			required: true,
		},
		value: {
			type: [String, Number, Boolean, FileList, Array],
			default: '',
		},
	},
	methods: {
		updateValue(e) {
			this.$emit('update:value', this.parseValue(e.target.value));
		},
	},
};

export default fieldMixin;
