import { mapGetters } from 'vuex';
import { RnHeader, RnButton, RnPagination, RnNewTable, RnOrderPlanner, RnTag } from '@/components';
import { batteriesMixin } from '@/mixins';
import { TagColors } from '@/components/tag/tag.enums';
import { ITEMS_PER_PAGE } from '@/services/constants';
import RnSpinner from '@/components/spinner/spinner.vue';
import { formatAsDate } from '@/utils/format-as-date';

export default {
	name: 'rn-cp-dashboard-view',
	components: {
		RnSpinner,
		RnHeader,
		RnButton,
		RnPagination,
		RnNewTable,
		RnOrderPlanner,
		RnTag,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DOUBLE,
			collectionOrdersTableHeaders: [
				{
					text: this.$t('table.order.status'),
					value: 'status',
					component: RnTag,
					compact: true,
					componentProps: (value) => {
						if (!value.status) {
							return null;
						}

						return {
							text: this.$t(`table.order.status.${value.status}`),
							color: TagColors[value.status.toLowerCase()],
						};
					},
				},
				{
					text: this.$t('table.order.nr'),
					value: 'number',
					compact: true,
				},
				{
					text: this.$t('table.order.dateRequested'),
					value: 'orderCreationDate',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('common.collectionPoint'),
					value: 'sourceBusinessRelation.name',
				},
				{
					text: this.$t('table.order.brand'),
					value: 'batteries[0].batteryDescription.brand.name',
				},
				{
					text: this.$t('common.collectionPartner'),
					value: 'owner.name',
				},
			],
			page: 0,
		};
	},
	computed: {
		...mapGetters(['userName', 'businessRelationId', 'spocCollectionOrders', 'spocCollectionOrdersCount']),
		collectionOrdersState() {
			if (!this.spocCollectionOrders) {
				return 'loading';
			}
			if (this.spocCollectionOrders.length) {
				return 'results';
			}
			return 'noResults';
		},
		downloadBatteryHeaders() {
			return [...this.existingBatteryTableHeaders, this.attachmentBatteryHeader];
		},
	},
	created() {
		this.updateCollectionOrdersPaginated(0);
	},
	methods: {
		planTransport() {
			this.$router.push({ name: 'CPNewTransport' });
		},
		planCollection(collectionOrderId) {
			this.$router.push({ name: 'CPNewCollection', params: { collectionOrderId } });
		},
		updateCollectionOrdersPaginated(page) {
			this.page = page;
			this.$store.dispatch('getSpocCollectionOrders', { businessRelationId: this.businessRelationId, page: page || 0, pageSize: this.ITEMS_PER_PAGE });
		},
	},
};
