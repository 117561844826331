import Vue from 'vue';

import RnIcon from '../icon/icon.vue';
import { ButtonTypes, ButtonClasses } from './button.enums';

export default Vue.extend({
	name: 'rn-button',
	components: {
		RnIcon,
	},
	props: {
		// Content / functionality based props
		type: {
			type: String,
			default: ButtonTypes.Button,
			validator: value => {
				const buttonTypes = [ButtonTypes.Button, ButtonTypes.Reset, ButtonTypes.Submit];

				return !!~buttonTypes.indexOf(value as string);
			},
		},
		icon: {
			type: Object,
			default: () => ({
				name: '', // icon name
				direction: '', // icon direction
				pos: '', // left or right
				original: false,
				width: '16px',
				height: '16px',
			}),
		},
		method: {
			type: Function,
			default: () => {
				// do nothing
			},
		},
		to: {
			type: Object,
			default: undefined,
		},
		text: {
			type: String,
			default: '',
		},
		// Class / appearance based props
		color: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: '',
		},
		outline: {
			type: Boolean,
			default: false,
		},
		round: {
			type: Boolean,
			default: false,
		},
		shrink: {
			type: Boolean,
			default: false,
		},
		transparent: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		disableWrap: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: Object,
			default: undefined,
		},
	},
	computed: {
		buttonClasses() {
			return {
				[ButtonClasses.Base]: true,
				// Icon
				[ButtonClasses.Icon]: !(this.$slots as any).default && !this.text,
				// Color
				[ButtonClasses.Blue]: this.color === 'blue',
				[ButtonClasses.Green]: this.color === 'green',
				[ButtonClasses.Red]: this.color === 'red',
				[ButtonClasses.Grey]: this.color === 'grey',
				[ButtonClasses.White]: this.color === 'white',
				// Appearance
				[ButtonClasses.Outline]: this.outline && this.color !== 'white',
				[ButtonClasses.OutlineWhite]: this.outline && this.color === 'white',
				[ButtonClasses.Round]: this.round,
				[ButtonClasses.Shrink]: this.shrink,
				[ButtonClasses.Nowrap]: this.disableWrap,
				[ButtonClasses.Transparent]: this.transparent,
				// Sizes
				[ButtonClasses.XSmall]: this.size === 'x-small',
				[ButtonClasses.Small]: this.size === 'small',
				[ButtonClasses.XLarge]: this.size === 'x-large',
				[ButtonClasses.FullWidth]: this.size === 'full-width',
			};
		},
	},
});
