import { mapGetters } from 'vuex';

import { Roles } from '@/enums/roles';
import { RnPUPDashboardView, RnCPOperationsDashboardView, RnCPSpocDashboardView, RnRCDashboardView, RnMFDashboardView } from '../index';

export default {
	name: 'rn-dashboard-view',
	computed: {
		...mapGetters(['defaultRole', 'modeCpOperationsOrSpoc']),
		dashboardView() {
			switch (this.defaultRole.name) {
				case Roles.PUP:
					return RnPUPDashboardView;

				case Roles.CP:
					if (this.modeCpOperationsOrSpoc === 'operations') {
						return RnCPOperationsDashboardView;
					}
					return RnCPSpocDashboardView;

				case Roles.RC:
					return RnRCDashboardView;

				case Roles.SD:
					return RnRCDashboardView;

				case Roles.MF:
					return RnMFDashboardView;

				default:
					break;
			}
		},
	},
};
