// General form mixin
// ---
// Stuff that all form mixins can use
// ------------------------------------------------------------------------- /

const formHelpersMixin = {
	methods: {
		resetFormModel(model) {
			Object.keys(model).forEach(key => {
				if (Array.isArray(model[key])) {
					model[key] = [];

					return;
				}
				model[key] = null;
			});
		},
		parseValue(value) {
			if (value === 'true') {
				return true;
			}
			if (value === 'false') {
				return false;
			}
			if (value === '') {
				return '';
			}
			if (!isNaN(+value)) {
				return +value;
			}
			return value;
		},
	},
};

export default formHelpersMixin;
