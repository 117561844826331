import { mapGetters } from 'vuex';
import get from "lodash/get";

import { ITEMS_PER_PAGE } from '@/services/constants';
import { RnNewTable, RnActionHeader, RnButton, RnSearch, RnField } from '@/components';
import RnPagination from '@/components/pagination/pagination.vue';
import { getInvoiceFile } from '@/services/invoices';
import { formatAsDate } from '@/utils/format-as-date';

export default {
	name: 'rn-ops-invoices-view',
	components: {
		RnNewTable,
		RnActionHeader,
		RnButton,
		RnPagination,
		RnSearch,
		RnField,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.default,
			opsInvoicesPage: 0,
		};
	},
	computed: {
		...mapGetters(['businessRelationId', 'opsInvoices', 'opsInvoicesCount']),

		invoiceHeaders: {
			get() {
				return [
					{
						text: this.$t('table.invoice.date'),
						value: 'date',
						sort: 'date',
						display: formatAsDate,
					},
					{
						text: this.$t('table.invoice.name'),
						value: 'number',
					},
					{
						text: this.$t('table.invoice.debtor'),
						value: 'debtor',
						sortable: false,
						display: (debtor) => `${debtor.name} ${get(debtor, 'roles[0].name') ? `(${get(debtor, 'roles[0].name')})` : ''}`,
					},
					this.actionHeader,
				];
			},
		},
		actionHeader() {
			return {
				text: '',
				value: 'download',
				compact: true,
				component: RnButton,
				sortable: false,
				align: 'right',
				componentProps: ({ id: invoiceId }) => ({
					method: () => this.downloadFile(invoiceId),
					outline: true,
					disableWrap: true,
					text: this.$t('common.download'),
					size: 'x-small',
					color: 'blue',
				}),
				date: null,
				search: '',
			}
		},
	},
	created() {
		this.$store.dispatch('getOpsInvoices', { businessRelationId: this.businessRelationId });
	},
	methods: {
		onSearchChange(value) {
			this.search = value;
			this.getInvoices();
		},
		onDateChange(date) {
			this.date = date;
			this.getInvoices();
		},
		downloadFile(invoiceId) {
			getInvoiceFile(this.businessRelationId, invoiceId);
		},
		getInvoices(page = 0) {
			this.opsInvoicesPage = page;
			this.$store.dispatch('getOpsInvoices', {
				businessRelationId: this.businessRelationId,
				page,
				pageSize: ITEMS_PER_PAGE.DEFAULT,
				search: this.search,
				date: this.date,
			});
		},
	},
};
