import { mapGetters } from 'vuex';

import serverService from '@/services/server';
import { RnActionHeader, RnButton, RnResourceCard, RnForm, RnModal, RnSidebar, RnIcon, RnNewTable, RnField, RnTabs } from '@/components';
import { addResourceFormMixin } from '@/mixins';
import { Roles } from '@/enums/roles';
import { MessageTypes } from '@/enums/message-types';
import { ButtonClasses } from '../../../components/button/button.enums';
import { logAndToastError } from '../../../utils/errorHandler';

export default {
	name: 'rn-resources-view',
	components: {
		RnActionHeader,
		RnButton,
		RnResourceCard,
		RnForm,
		RnModal,
		RnSidebar,
		RnIcon,
		RnNewTable,
		RnField,
		RnTabs,
	},
	mixins: [addResourceFormMixin],
	data() {
		return {
			currentTabIndex: 0,
			currentCategory: 'safety',
			showModal: false,
			uploadResourceSaveLabel: this.$t('common.save'),
			state: {
				sidebarOpen: false,
				manageResourceStep: 'overview',
			},
		};
	},
	computed: {
		...mapGetters(['defaultRole', 'resources']),
		canManageResources() {
			return this.defaultRole.name === Roles.MF;
		},
		steps() {
			return [
				{ label: this.$t('resources.category.safety'), category: 'safety' },
				{ label: this.$t('resources.category.manual'), category: 'instruction' },
			];
		},
		activeResources() {
			if (this.resources) {
				return this.resources.filter(resource => resource.category === this.currentCategory);
			}
			return [];
		},
		tableHeaders() {
			return [
				{
					text: this.$t('resources.columns.name'),
					value: 'title',
				},
				{
					text: this.$t('resources.columns.description'),
					value: 'description',
				},
				{
					text: this.$t('resources.columns.actions'),
					value: 'id',
					compact: true,
					display: () => 'Edit',
					component: RnButton,
					componentProps: ({ id }) => ({
						icon: { name: 'pencil-alt', width: '10px', height: '10px' },
						color: 'blue',
						method: () => this.editResource(id),
						transparent: true,
						size: 'small',
						text: this.$t('common.edit'),
					}),
				},
				{
					text: '',
					component: RnButton,
					compact: true,
					componentProps: ({ id }) => ({
						icon: { name: 'cross-r', width: '25px', height: '25px' },
						color: ButtonClasses.Grey,
						method: () => this.confirmDelete(id),
						transparent: true,
						size: 'small',
						text: '',
					}),
				},
			];
		},
	},
	created() {
		this.$store.dispatch('getAllResources');
	},
	methods: {
		setActiveTab(index, category) {
			this.currentTabIndex = index;
			this.currentCategory = category;
		},
		confirmDelete(id) {
			this.resourceToDelete = id;
			this.toggleModal(true);
		},
		editResource(id) {
			const resources = this.resources.filter(r => r.id === id);
			if (!resources || !resources.length) {
				logAndToastError(this.$store.dispatch, this.$t('message.resource.notFound'), null, { resources, id });

				return;
			}
			this.addResourceFormModel = {
				...resources[0],
				resourceId: resources[0].id,
			};
			this.state.manageResourceStep = 'upload';
		},
		toggleModal(state) {
			this.showModal = state;
		},
		dismissModal() {
			this.toggleModal(false);
			this.resourceToDelete = null;
		},
		deleteResource() {
			const url = '/resources';
			const body = { data: { id: this.resourceToDelete } };
			serverService
				.delete(url, body)
				.then(() => {
					this.toggleModal(false);
					this.$store.dispatch('getAllResources');
					this.$store.dispatch('setMessage', { text: 'message.resource.delete.success', type: MessageTypes.SUCCESS });
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to deleteResource', err, { url, body });
				});
		},
		downloadAll() {
			window.open('/server/resources/zip', '_blank');
		},
		uploadResource() {
			if (this.addResourceFormModel) {
				this.uploadResourceSaveLabel = this.$t('common.saving');
				this.addResourceFormModel.category = this.currentCategory;
				this.addResourceFormAction(this.resourceUploadSuccess, this.resourceUploadFailed);
			} else {
				logAndToastError(this.$store.dispatch, this.$t('message.resource.upload.categoryNotFound'), null, {
					addResourceFormModel: this.addResourceFormModel,
				});
			}
		},
		resourceUploadSuccess() {
			this.clearAddResourceFormModel();
			this.state.manageResourceStep = 'overview';
			this.uploadResourceSaveLabel = this.$t('common.save');
		},
		resourceUploadFailed() {
			this.uploadResourceSaveLabel = this.$t('common.save');
			logAndToastError(this.$store.dispatch, this.$t('message.resource.upload.failed'), null);
		},
		backToResourceOverview() {
			this.clearAddResourceFormModel();
			this.state.manageResourceStep = 'overview';
		},
		onSidebarOpenClose(isOpen) {
			if (!isOpen) {
				this.backToResourceOverview();
			}
		},
	},
};
