import { mapGetters } from 'vuex';

import { logAndToastError } from '@/utils';
import apiService from '@/services/api';
import { ITEMS_PER_PAGE } from '@/services/constants';

import {
	RnActionHeader,
	RnNotification,
	RnButton,
	RnPagination,
} from '@/components';

export default {
	name: 'rn-notifications-view',
	components: {
		RnActionHeader,
		RnButton,
		RnNotification,
		RnPagination,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DOUBLE,
			page: 0,
			activeTab: 'unread',
		};
	},
	computed: {
		...mapGetters(['readNotifications', 'unreadNotifications', 'readNotificationsCount', 'unreadNotificationsCount', 'userId']),
		notifications() {
			return this.activeTab === 'unread' ? this.unreadNotifications : this.readNotifications;
		},
		totalItems() {
			return this.activeTab === 'unread' ? this.unreadNotificationsCount : this.readNotificationsCount;
		},
	},
	methods: {
		getNotifications(page = 0) {
			this.page = page;

			this.$store.dispatch('getAllNotifications', {
				skipReadNotifications: false,
				page,
				itemsPerPage: this.ITEMS_PER_PAGE,
			});
		},
		setActiveTab(tab) {
			this.getNotifications(0);
			this.activeTab = tab;
		},
		markAllAsRead() {
			const url = `/users/${this.userId}/notifications/markallasread`;
			apiService
				.put(url)
				.then(({ data: response }) => {
					if (response.success) {
						this.$store.dispatch('getAllNotifications');
					}
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to mark all notifications as read', err, {
						url,
					});
				});
		},
	},
};
