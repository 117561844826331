import { mapGetters } from 'vuex';
import { mapLimit } from 'blend-promise-utils';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { addBatteryFormMixin, transportPackagingFormMixin } from '@/mixins';
import { MessageTypes } from '@/enums/message-types';
import {
	RnIcon,
	RnButton,
	RnBatteryList,
	RnDropzoneManager,
	RnField,
	RnHeader,
	RnNewTable,
	RnForm,
	RnStepForm,
	RnSidebar,
	RnSpinner,
	RnTag,
	RnFileItem,
	RnDropzones,
	RnDataList,
	RnModal,
} from '@/components';

import logger from '@/services/logger';
import { linkAttachmentToBattery } from '@/services/attachment';
import { generateHash, logAndToastError, formatTransportPackagings } from '@/utils';
import { formatWeight } from '@/utils/units';
import { getCollectionAddress } from '@/utils/locations';

import { getBatteryStatusInfo, prepareAttachments, removeAttachment, getBatteryDTO } from '@/services/new-battery';
import RecursiveError from '@/services/recursiveError';

import { addFormFieldsToBattery } from './new-request.helper';
import { patchBattery, deleteBattery, setBattery, setCollectionOrder, setPackaging } from './new-request.service';

export default {
	name: 'rn-pup-new-request-view',
	components: {
		RnIcon,
		RnButton,
		RnField,
		RnHeader,
		RnNewTable,
		RnForm,
		RnDataList,
		RnStepForm,
		RnSidebar,
		RnSpinner,
		RnTag,
		RnFileItem,
		RnDropzones,
		RnBatteryList,
		RnDropzoneManager,
		RnModal,
	},
	mixins: [addBatteryFormMixin, transportPackagingFormMixin],
	data() {
		return {
			state: {
				currentStep: 1,
				currentAddBatteryStep: 1,
				selectedPackaging: [],
				sidebarOpen: false,
				batteries: [],
				isEditing: false,
				isLoading: false,
				renderDropzones: true,
				defaultZoneCount: 0,
				allowCustomPackaging: false,
				emptyZones: false,
				showModal: false,
				tempBattery: null,
			},
			comments: null,
			isUploadingAttachments: false,
		};
	},
	computed: {
		...mapGetters(['locationId', 'locationsLocation', 'locations', 'businessRelationId', 'chemicalFamilies', 'batteryConditions', 'brands']),
		steps() {
			const { batteries, isLoading } = this.state;
			const hasBatteriesSelected = !!batteries.length;

			if (!hasBatteriesSelected && this.state.currentStep === 3) {
				this.changeStep();
			}

			return [
				{
					label: this.$t('stepForm.steps.selectBatteries'),
					form: this.addBatteryForm,
					valid: hasBatteriesSelected,
				},
				{
					label: this.$t('stepForm.steps.selectPackaging'),
					form: this.transportPackagingForm,
					confirm: {
						disabled: !this.state.emptyZones,
						title: this.$t('pup.newRequest.steps.checkTransportPackaging.confirm.title'),
						message: this.$t('pup.newRequest.steps.checkTransportPackaging.confirm.message'),
						action: this.$t('pup.newRequest.steps.checkTransportPackaging.confirm.action'),
					},
					valid: () => {
						const hasUnpackagedOptions = this.state.selectedPackaging.filter(transportPackaging => !transportPackaging.id).length;
						const hasPackagedOptions = this.state.selectedPackaging.filter(transportPackaging => !!transportPackaging.id).length;
						if (hasUnpackagedOptions && hasPackagedOptions) {
							// some packaging indicates unpackaged batteries
							this.$store.dispatch('setMessage', {
								text: 'pup.newRequest.steps.selectPackaging.noPackagedAndUnpackagedCombinations',
								type: MessageTypes.ERROR,
							});

							return false;
						}

						return true;
					},
				},
				{
					label: this.$t('stepForm.steps.checkAndRequest'),
					valid: hasBatteriesSelected && !isLoading,
					confirm: {
						title: this.$t('pup.newRequest.steps.checkAndRequest.confirm.title'),
						message: this.$t('pup.newRequest.steps.checkAndRequest.confirm.message'),
						action: this.$t('pup.newRequest.steps.checkAndRequest.confirm.action'),
					},
					action: {
						icon: {
							name: 'check',
							pos: 'left',
							width: '16px',
							height: '16px',
						},
						method: this.requestCollection,
						text: this.$t('common.requestCollection'),
					},
				},
			];
		},
		activeForm() {
			const activeIndex = Math.max(0, this.state.currentStep - 1);

			if (this.state.isEditing) {
				return this.steps[0].form;
			}

			return this.steps[activeIndex].form || {};
		},
		batteriesWeight() {
			if (this.state.batteries.length) {
				return formatWeight(this.state.batteries.reduce((acc, battery) => acc + battery.nettoWeight, 0));
			}
		},
		overviewDataList() {
			return [
				{
					key: this.$t("pup.newRequest.address"),
					value: getCollectionAddress(this.locations),
				},
				{
					key: this.$t("common.totalWeight"),
					value: this.batteriesWeight,
				},
				{
					key: this.$t("pup.newRequest.totalBatteries"),
					value: this.state.batteries.length,
				},
				{
					key: this.$t("common.transportation"),
					value: formatTransportPackagings(this.state.selectedPackaging).map(transportPackaging => (
						{
							title: `${transportPackaging.amount} x ${transportPackaging.name}`,
							description: `${transportPackaging.width} x ${transportPackaging.height} x ${transportPackaging.depth} ${this.$t('common.mm')}`,
						}
					)),
				},
			];
		},
		batteriesSuitedForTransport() {
			return this.state.batteries.filter((battery) => battery.suitedForTransport === true && battery.packagingOption !== "COPackagingRequired");
		},
		batteriesCOPackagingRequired() {
			return this.state.batteries.filter((battery) => battery.packagingOption === "COPackagingRequired");
		},
		batteriesNotSuitedForTransport() {
			return this.state.batteries.filter((battery) => !battery.suitedForTransport && battery.packagingOption !== "COPackagingRequired");
		},
		isSerialNumberRequired() {
			const activeBrand = this.brands.find((brand) => brand.id === this.selectedBrandId) || {};
			return get(activeBrand, "isSerialNumberRequired", false);
		},
		isUniqueSerialNumberRequired() {
			const activeBrand = this.brands.find((brand) => brand.id === this.selectedBrandId) || {};
			return get(activeBrand, "isUniqueSerialNumberRequired", false);
		},
		getAddBatteryStepLabel() {
			return `Step ${this.state.currentAddBatteryStep} / 3`;
		},
		batteryStatusInfo() {
			return getBatteryStatusInfo(this.$t, this.batteryConditions);
		},
		activeBatteryStatus() {
			return this.batteryStatusInfo.find((status) => status.id === this.batteryFormModel.batteryConditionId);
		},
		batteryPackagings() {
			return this.$store.getters.getBatteryPackagings(this.businessRelationId, this.batteryFormModel.brand);
		},
		location() {
			return this.locationsLocation(this.locationId);
		},
		contract() {
			return get(this.location, 'contractPackagings[0]', true);
		},
		selectedBrandId() {
			if (this.batteryFormModel.brand) {
				return this.batteryFormModel.brand;
			}

			if (this.state.batteries[0]) {
				return this.state.batteries[0].brand;
			}

			return null;
		},
	},
	watch: {
		"batteryFormModel.isBatteryStable"() {
			this.handleBatteryPackagingOption();
		},
		"batteryFormModel.damaged"() {
			this.handleBatteryPackagingOption();
		},
	},
	created() {
		this.$store.dispatch('getChemicalFamilies');
		this.$store.dispatch('getBatteryConditions');

		if (this.locationId) {
			this.$store.dispatch('getLocation', { locationId: this.locationId });
		}
	},
	mounted() {
		this.resetFormModel(this.batteryFormModel);
	},
	methods: {
		handleBatteryPackagingOption() {
				// Search for the proper contract parameters to define which battery packaging options are available.
				const batteryConditionId = this.batteryFormModel.batteryConditionId || null;
				const contractPackagingOptions = get(this.location, 'contractPackagings[0].contractPackagingOption', null);
				let contractPackagingOption = null;
				let option = null;

				if (contractPackagingOptions) {
					contractPackagingOption = contractPackagingOptions.find((option) => {
						return option.batteryCondition.id === batteryConditionId;
					});
				}

				if (contractPackagingOption) {
					option = get(contractPackagingOption, 'packagingOption.name', null);
				}

				if (option === "COPackagingRequired") {
					this.batteryFormModel.noBatteryPackaging = true;
				} else {
					this.batteryFormModel.noBatteryPackaging = false;
				}

				this.batteryFormModel.packagingOption = option;
		},
		addBatteryValidateAndGotoNextStep() {
			// Validate part of the addBattery model
			if (this.validateBatteryFormModel(this, this.batteryFormModel, this.state.currentAddBatteryStep)) {
				this.state.currentAddBatteryStep += 1;
			}
		},
		async handleSaveBatteryInDb(batteryData) {
			const newBattery = addFormFieldsToBattery(batteryData, this.batteryPackagings);
			if (this.state.isEditing) {
				await this.updateBattery(newBattery);
			} else {
				await this.createBattery(newBattery);
			}

			this.addBatteryFinished();
		},
		async saveBattery() {
			if (!this.validateBatteryFormModel(this, this.batteryFormModel, 3)) {
				return;
			}

			this.state.isLoading = true;

			let batteryData = {
				...this.batteryFormModel,
			};

			try {
				if (this.batteryFormModel.customBatteryPackaging) {
					const batteryPackagingId = await setPackaging({
						...this.batteryFormModel.packaging,
						packagingIconId: "",
						packagingTypeId: "a8ef86bb-2d1f-4a0e-838d-ef534baeeede",
					}, this.locationId);

					await this.$store.dispatch('getBatteryPackagings', {
						businessRelationId: this.businessRelationId,
						brandId: this.selectedBrandId,
					});

					if (batteryPackagingId) {
						batteryData = {
							...batteryData,
							batteryPackagingId: batteryPackagingId,
						}
					}

					await this.handleSaveBatteryInDb(batteryData);
				} else {
					await this.handleSaveBatteryInDb(batteryData);
				}
			} catch (err) {
				logAndToastError(this.$store.dispatch, 'Failed to send battery to server', err);
			} finally {
				this.state.isLoading = false;
			}
		},
		editBattery(batteryData) {
			this.toggleSidebar(true);

			this.batteryFormModel = {
				...this.batteryFormModel,
				...batteryData,
			}

			this.state.isEditing = true;
		},
		async deleteBattery(battery) {
			const toRemoveBattery = this.state.batteries.find(b => b.id === battery.id);
			const data = await deleteBattery(battery.id);

			if (data.success) {
				const toRemoveBatteryIndex = this.state.batteries.indexOf(toRemoveBattery);
				this.state.batteries.splice(toRemoveBatteryIndex, 1);
			}
		},
		async duplicateBattery(battery) {
			if (battery) {
				this.state.tempBattery = {
					...battery,
				}
			}

			if (battery && this.isUniqueSerialNumberRequired) {
				this.state.tempBattery.serialNumber = "";
			}

			if (this.isSerialNumberRequired && !this.state.tempBattery.serialNumber) {
				this.state.showModal = true;

				return;
			}

			if (!battery && !!this.state.tempBattery.serialNumber) {
				this.state.showModal = false;
			}

			await this.handleSaveBatteryInDb(this.state.tempBattery);
		},
		async updateBattery(batteryInfo) {
			return new Promise((resolve, reject) => {
				patchBattery(batteryInfo.id, getBatteryDTO(batteryInfo))
					.then(async (data) => {
						const toEditBatteryIndex = this.state.batteries.findIndex(battery => battery.id === batteryInfo.id);

						if (data.success && toEditBatteryIndex > -1) {
							// Directly updating an item in an array doesn't update vue (computed lazy update)
							this.$set(this.state.batteries, toEditBatteryIndex, batteryInfo);
						}

						// Link attachments to batteryId
						const attachmentLinkFunctions = batteryInfo.attachments.map(attachment => () =>
							linkAttachmentToBattery(attachment.attachmentId, batteryInfo.id)
						);

						const results = await mapLimit(attachmentLinkFunctions, 2, async requestFunction => await requestFunction());

						Promise.all(results)
							.then(resolve)
							.catch(reject);
					})
					.catch(err => {
						logAndToastError(this.$store.dispatch, 'Failed to send battery to server', err);
						reject(err);
					});
			});
		},
		async createBattery(batteryInfo) {
			return new Promise((resolve, reject) => {
				setBattery(getBatteryDTO(batteryInfo), this.locationId)
					.then(async (batteryId) => {
						this.state.batteries.push({
							...batteryInfo,
							id: batteryId,
						});

						// Link attachments to batteryId
						const attachmentLinkFunctions = batteryInfo.attachments.map(attachment => () =>
							linkAttachmentToBattery(attachment.attachmentId, batteryId)
						);

						const results = await mapLimit(attachmentLinkFunctions, 2, async requestFunction => await requestFunction());

						Promise.all(results)
							.then(resolve)
							.catch(reject);
					})
					.catch(err => {
						logAndToastError(this.$store.dispatch, 'Failed to create battery', err);
						reject(err);
					});
			});
		},
		addBatteryFinished() {
			this.resetFormModel(this.batteryFormModel);
			this.toggleSidebar(false);
			this.state.isEditing = false;
			this.state.currentAddBatteryStep = 1;
		},
		addTransportPackaging() {
			if (this.validateTransportPackagingFormModel(this.transportPackagingFormModel)) {
				const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, this.selectedBrandId);

				this.transportPackagingFormModel.packagings
					.map((item) => {
					const transportPackaging = transportPackagings.find(packaging => packaging.id === item.id) || {};

					for (let i = 0; i < item.value; i++) {
						const packagingInfo = {
							...this.transportPackagingFormModel.transportPackaging,
							amount: item.value,
							tempId: generateHash(),
						};

						this.state.selectedPackaging.push({
							...transportPackaging,
							...packagingInfo,
						});
					}
				});

				this.resetAddPackagingFormModel();
				this.toggleSidebar(false);
				this.forceDropzonesRerender();
			}
		},
		toggleSidebar(openState) {
			this.state.allowCustomPackaging = get(this.location, 'contractPackagings[0].allowCustomPackaging', false);
			this.state.sidebarOpen = openState;
		},
		removePackaging(tempId) {
			const packagings = cloneDeep(this.state.selectedPackaging);
			const toRemoveId = packagings.find(packaging => tempId === packaging.tempId);
			const toRemoveIdIndex = packagings.indexOf(toRemoveId);

			packagings.splice(toRemoveIdIndex, 1);

			Object.keys(packagings).forEach(key => {
				if (packagings[key].length === 0) {
					delete packagings[key];
				}
			});

			this.state.selectedPackaging = [...packagings];

			this.forceDropzonesRerender();
		},
		async requestCollection() {
			this.state.isLoading = true;

			return new Promise((resolve, reject) => {
				let transportPackagings = formatTransportPackagings(this.state.selectedPackaging).map(packagingMethod => ({
					id: packagingMethod.id,
					amount: packagingMethod.amount,
				}));

				const orderData = {
					transportPackagings,
					batteryIds: this.state.batteries.map(b => b.id),
					// destinationLocationId: ,
					remarks: this.comments,
				};

				setCollectionOrder(this.locationId, orderData)
					.then(response => {
						if (response && response.success) {
							this.$store.dispatch('setMessage', {
								text: 'message.requestCollection.success',
								type: MessageTypes.SUCCESS,
							});
							this.$router.push({ name: 'Dashboard' });

							this.state.batteries = [];
							resolve();
						} else {
							reject();
						}
					})
					.catch(err => {
						logger.error(
							new RecursiveError('Failed to plan new transport', err, {
								locationId: this.locationId,
								orderData,
							})
						);
						logAndToastError(this.$store.dispatch, this.$t('message.assembleTransport.error'), err);
						reject(err);
					});
			})
			.catch(err => {
				reject(err);
			}).finally(() => {
				this.state.isLoading = false;
			});
		},
		closeSidebar(model) {
			this.resetFormModel(model);
			this.resetAddPackagingFormModel();
			this.toggleSidebar(false);
			this.state.isEditing = false;
		},
		async addCustomTransportPackaging() {
			const valid = this.validateTransportPackagingFormModel(this.transportPackagingFormModel, true);

			if (!valid) {
				return;
			}

			const { name, width, height, depth, weight, packagings } = this.transportPackagingFormModel;

			try {
				const id = await setPackaging({
					name,
					suitedForTransport: true, // Transport packagings are always suited for transport.
					height,
					width,
					depth,
					weight,
					packagingIconId: '',
					packagingTypeId: '21975384-b572-481f-956b-a93448b19eed',
				}, this.locationId);

				if (id) {
					await this.$store.dispatch('getTransportPackagings', {
						businessRelationId: this.businessRelationId,
						brandId: this.selectedBrandId,
					});

					this.resetAddPackagingFormModel();
					this.updateTransportPackagingFormModel({
						packagings: [
							...packagings,
							{
								id: id,
								value: 1,
							}
						],
					});
				}
			} catch (err) {
				logAndToastError(this.$store.dispatch, 'Failed to send custom packaging to server', err);
			}
		},
		prepareAttachments(files, type) {
			prepareAttachments(this, files, type);
		},
		removeAttachment(attachmentId) {
			removeAttachment(this, attachmentId);
		},
		onSidebarOpenOrClosed(isOpen) {
			if (!isOpen) {
				this.resetFormModel(this.batteryFormModel);
				this.resetAddPackagingFormModel();
				this.state.currentAddBatteryStep = 1;
				this.state.isEditing = false;
			}
		},
		changeStep(toStep) {
			if (toStep === 'previous') {
				this.$refs.stepForm.previousStep();

				return;
			}
			this.$refs.stepForm.toAddBatteryStep();
		},

		async goBack() {
			const promises = this.state.batteries.map((battery) => {
				return deleteBattery(battery.id);
			});

			await Promise.all(promises);

			this.$router.push({ name: 'Dashboard' });
		},

		async handleDropzone(data) {
			let battery = this.state.batteries.find((battery) => battery.id === data.draggableId);

			if (!battery) {
				return;
			}

			battery = {
				...battery,
				transportPackagingId: data.dropzoneId ? data.dropzoneId : null,
			}

			const newBattery = addFormFieldsToBattery(battery, this.batteryPackagings);
			await this.updateBattery(newBattery);
		},

		transportPackagingZones() {
			return this.state.selectedPackaging ? this.state.selectedPackaging.map((packaging) => {
				return {
					...packaging,
					icon: packaging.icon ? packaging.icon.name : "custom-package",
					title: packaging.name,
					subtitle: `${packaging.width} x ${packaging.height} x ${packaging.depth} ${this.$t('common.mm')}`,
					noPackaging: false,
				}
			}) : [];
		},

		forceDropzonesRerender() {
			this.state.renderDropzones = false;

			this.$nextTick(() => {
				this.state.renderDropzones = true;
			});
		},

		updateDefaultZoneCount(zoneCount) {
			this.state.defaultZoneCount = zoneCount;
		},

		updateEmptyZones(empty) {
			this.state.emptyZones = empty;
		}
	},
};
