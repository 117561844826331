import { render, staticRenderFns } from "./invoices.vue?vue&type=template&id=d6d8c01e"
import script from "./invoices.js?vue&type=script&lang=js&external"
export * from "./invoices.js?vue&type=script&lang=js&external"
import style0 from "./invoices.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports