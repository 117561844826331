import { patchBattery } from '@/services/new-battery';
import { MessageTypes } from '@/enums/message-types';
import { logAndToastError } from '@/utils';
import { linkAttachmentToBattery } from '@/services/attachment';

export const getPatchItem = async (battery, newBattery, $store, $t) => {
	const patchItem = {};

	if (battery.nettoWeight !== newBattery.nettoWeight) {
		patchItem.nettoWeight = newBattery.nettoWeight;
	}

	if (battery.damagedDescription !== newBattery.damagedDescription) {
		patchItem.damagedDescription = newBattery.damagedDescription;
	}

	if (battery.receptacle && battery.receptacle.id !== newBattery.batteryPackagingId) {
		patchItem.receptacleId = newBattery.batteryPackagingId;
	}

	if (newBattery.attachments.length > 0) {
		newBattery.attachments.map(attachment => {
			linkAttachmentToBattery(attachment.attachmentId, battery.id);
		});
	}

	if (!newBattery.damaged && newBattery.isBatteryStable) {
		patchItem.damagedDescription = '';
	}

	if (Object.keys(patchItem).length > 0) {
		try {
			await patchBattery(battery.id, patchItem);

			$store.dispatch('setMessage', { text: $t('edit.patchBatterySucces'), type: MessageTypes.SUCCESS });

			return patchItem;
		} catch (err) {
			$store.dispatch('setMessage', { text: $t('edit.patchBatteryError'), type: MessageTypes.ERROR });
			logAndToastError('patchBattery', $t('edit.patchBatteryErro'), err);
		}
	}
};
