import RnCard from './card/card.vue';

export default {
	name: 'rn-card-list',
	components: {
		RnCard,
	},
	props: {
		cardData: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		hasDefaultSlot() {
			return !!this.$slots.action
		},
	}
};
