// Locations store module
// ---
//
// ------------------------------------------------------------------------- /
import Vue from 'vue';
import get from 'lodash/get';
import set from 'lodash/set';

import apiService from '@/services/api';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { downloadExcelFile, generateFilterString, logAndToastError, resetState } from '../../utils';
import pupsMetadata from "@/assets/api/pups-filters-metadata.json";

// Helpers
// ------------------------------------------------------------------------- /
function exportLocations(body, commit) {
	const filterString = generateFilterString({
		...body,
		tableHeaders: get(pupsMetadata, 'tableHeaders', []),
	});

	body.ODataFilter = filterString || null;

	commit('setIsLoading', {
		isLoading: true,
	});

	const url = `locations/search/excel`;
	apiService
		.post(url, body)
		.then(response => {
			downloadExcelFile(response);
		})
		.catch(err => {
			logAndToastError(commit, 'Failed to export pickup points', err, {
				url,
				body,
			});
		})
		.finally(() => {
			commit('setIsLoading', {
				isLoading: false,
			});
		});
}

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	items: {},
	locationsByRoles: {},
	locationsByBrand: {},
	isLoading: false,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	locationsLocation: state => locationId => get(state.items, locationId),
	locationsByRoles: state => get(state, 'locationsByRoles.items', []),
	locationsByBrand: state => get(state, 'locationsByBrand.items', []),
	locationsByBrandCount: state => get(state, 'locationsByBrand.totalItems', 0),
	isLoading: state => state.isLoading,
	getAllowCustomPackaging: state => (locationId, brandId) => {
		const location = get(state, `items[${locationId}]`, undefined);

		return location?.contractPackagings?.find(({ brand }) => brand.id === brandId)?.allowCustomPackaging ?? false;
	},
	getPackagingOption: state => (locationId, brandId, batteryConditionId) => {
		const location = get(state, `items[${locationId}]`, undefined);

		return location?.contractPackagings?.find(({ brand }) => brand.id === brandId)?.contractPackagingOption?.find(({ batteryCondition }) => batteryCondition.id === batteryConditionId)?.packagingOption?.name ?? null;
	}
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getLocation({ commit, state }, { locationId }) {
		if (!state.items[locationId]) {
			const url = `/locations/${locationId}`;
			apiService
				.get(url)
				.then(({ data: locationDescription }) => {
					commit('setLocation', { locationId, locationDescription });
				})
				.catch(err => {
					logAndToastError(commit, 'Failed to get location from the server', err, { url });
				});
		}
	},
	getLocationsByRoles({ commit }, { roleIds, businessRelationId }) {
		if (roleIds.length) {
			const url = `locations/searchforintegration?page=0&pageSize=${ITEMS_PER_PAGE.DEFAULT}`;
			const body = {
				businessRelationRoles: roleIds,
				...(businessRelationId && { linkedCOId: businessRelationId }),
			};
			apiService
				.post(url, body)
				.then(({ data: locations }) => {
					commit('setLocationsByRoles', { locations });
				})
				.catch(err => {
					logAndToastError(commit, 'Failed to get locations by roles from the server', err, { url, body });
				});
		}
	},
	getLocationsByBrand({ commit }, { page = 0, brandId, excel = false }) {
		const body = {
			...(brandId && { linkedBrandId: brandId }),
		};

		const filterString = generateFilterString({
			...body,
			tableHeaders: get(pupsMetadata, 'tableHeaders', []),
		});

		body.ODataFilter = filterString || null;

		if (excel) {
			exportLocations(body, commit);
			return;
		}

		const url = `locations/search?page=${page}&pageSize=${ITEMS_PER_PAGE.DEFAULT}`;
		apiService
			.post(url, body)
			.then(({ data: locations }) => {
				commit('setLocationsByBrand', { locations });
			})
			.catch(err => {
				logAndToastError(commit, 'Failed to get locations by roles from the server', err, { url, body });
			});
	},
	resetLocations({ commit }) {
		commit('resetLocations');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setLocation(state, { locationId, locationDescription }) {
		Vue.set(state.items, locationId, locationDescription);
	},
	setLocationsByRoles(state, { locations }) {
		state.locationsByRoles = locations;
	},
	setLocationsByBrand(state, { locations }) {
		state.locationsByBrand = locations;
	},
	resetLocations(state) {
		resetState(state, initialState);
	},
	setIsLoading(state, { isLoading }) {
		set(state, "isLoading", isLoading);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
