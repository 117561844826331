// CollectionOrders store module
// ---
//
// ------------------------------------------------------------------------- /
import Vue from 'vue';
import get from 'lodash/get';
import set from 'lodash/set';

import apiService from '@/services/api';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { logAndToastError, resetState, generateFilterString, downloadExcelFile } from '../../utils';
import collectionOrdersMetadata from "@/assets/api/collection-orders-filters-metadata.json";

// Helpers
// ------------------------------------------------------------------------- /
function searchCollectionOrders(businessRelationId, body, page, pageSize, storePath, storeCountPath, commit) {
	const filterString = generateFilterString({
		...body,
		tableHeaders: get(collectionOrdersMetadata, 'tableHeaders', []),
	});

	body.ODataFilter = filterString || null;

	commit('setCollectionOrders', {
		items: null,
		storePath,
	});
	const url = `/businessrelations/${businessRelationId}/collectionorders/search?page=${page || 0}&pageSize=${pageSize || ITEMS_PER_PAGE.DEFAULT}`;
	apiService
		.post(url, body)
		.then(response => {
			commit('setCollectionOrders', {
				items: get(response, 'data.items', []),
				storePath,
			});
			commit('setCollectionOrdersCount', {
				count: get(response, 'data.totalItems', 0),
				storeCountPath,
			});
		})
		.catch(err => {
			logAndToastError(commit, 'Failed to getCollectionOrders', err, {
				url,
				body,
			});
		});
}

function exportCollectionOrders(businessRelationId, body, commit) {
	const filterString = generateFilterString({
		...body,
		tableHeaders: get(collectionOrdersMetadata, 'tableHeaders', []),
	});

	body.ODataFilter = filterString || null;

	commit('setIsLoading', {
		isLoading: true,
	});

	const url = `/businessrelations/${businessRelationId}/collectionorders/search/excel`;
	apiService
		.post(url, body)
		.then(response => {
			downloadExcelFile(response);
		})
		.catch(err => {
			logAndToastError(commit, 'Failed to export collection orders', err, {
				url,
				body,
			});
		})
		.finally(() => {
			commit('setIsLoading', {
				isLoading: false,
			});
		});
}

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	ownerItems: null,
	ownerItemsCount: 0,
	pupItems: null,
	pupItemsCount: 0,
	pupCreatedItems: null,
	pupCreatedItemsCount: 0,
	pupPendingItems: null,
	pupPendingItemsCount: 0,
	pupCheckedItems: null,
	pupCheckedItemsCount: 0,
	incomingUnplannedItems: null,
	incomingUnplannedItemsCount: 0,
	incomingPlannedItems: null,
	incomingPlannedItemsCount: 0,
	incomingUncheckedItems: null,
	incomingUncheckedItemsCount: 0,
	incomingItems: null,
	incomingItemsCount: 0,
	brandItems: null,
	brandItemsCount: 0,
	plannedOnMonthItems: null,
	plannedOnMonthItemsCount: 0,
	items: null,
	itemsCount: 0,
	spocItems: null,
	spocItemsCount: 0,
	packaging: {},
	isLoading: false,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	ownerCollectionOrders: state => state.ownerItems,
	ownerCollectionOrdersCount: state => state.ownerItemsCount || 0,
	pupCollectionOrders: state => state.pupItems,
	pupCollectionOrdersCount: state => state.pupItemsCount || 0,
	pupCreatedCollectionOrders: state => state.pupCreatedItems,
	pupCreatedCollectionOrdersCount: state => state.pupCreatedItemsCount || 0,
	pupPendingCollectionOrders: state => state.pupPendingItems,
	pupPendingCollectionOrdersCount: state => state.pupPendingItemsCount || 0,
	pupCheckedCollectionOrders: state => state.pupCheckedItems,
	pupCheckedCollectionOrdersCount: state => state.pupCheckedItemsCount || 0,
	incomingUnplannedCollectionOrders: state => state.incomingUnplannedItems,
	incomingUnplannedCollectionOrdersCount: state => state.incomingUnplannedItemsCount || 0,
	incomingPlannedCollectionOrders: state => state.incomingPlannedItems,
	incomingPlannedCollectionOrdersCount: state => state.incomingPlannedItemsCount || 0,
	incomingUncheckedCollectionOrders: state => state.incomingUncheckedItems,
	incomingUncheckedCollectionOrdersCount: state => state.incomingUncheckedItemsCount || 0,
	incomingCollectionOrders: state => state.incomingItems,
	incomingCollectionOrdersCount: state => state.incomingItemsCount || 0,
	brandCollectionOrders: state => state.brandItems,
	brandCollectionOrdersCount: state => state.brandItemsCount || 0,
	plannedOnMonthCollectionOrders: state => state.plannedOnMonthItems,
	// plannedOnMonthCollectionOrdersCount: state => state.plannedOnMonthItemsCount || 0,
	spocCollectionOrders: state => state.spocItems,
	spocCollectionOrdersCount: state => state.spocItemsCount || 0,
	collectionOrders: state => state.items,
	collectionOrdersCount: state => state.itemsCount, // Will always be 1, but we keep this for consistency sake
	isLoading: state => state.isLoading,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getPupCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'pupItems', 'pupItemsCount', commit);
	},
	async getPupPendingCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			destinationBusinessRelationId: businessRelationId,
			statusIds: [
				'Planned',
				'Created',
				// "Blocked", // TODO Wait for loqutus to add this state (RENEO-270)
			],
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'pupPendingItems', 'pupPendingItemsCount', commit);
	},
	async getPupCheckedCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			destinationBusinessRelationId: businessRelationId,
			statusIds: [
				'Executed',
				'ExecutedWithRemarks',
				'Cancelled',
			],
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'pupCheckedItems', 'pupCheckedItemsCount', commit);
	},
	getOwnerCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			ownerId: businessRelationId,
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'ownerItems', 'ownerItemsCount', commit);
	},
	async getIncomingUnplannedCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			destinationBusinessRelationId: businessRelationId,
			statusIds: ['Created'],
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'incomingUnplannedItems', 'incomingUnplannedItemsCount', commit);
	},
	async getIncomingPlannedCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			destinationBusinessRelationId: businessRelationId,
			statusIds: ['Planned'],
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'incomingPlannedItems', 'incomingPlannedItemsCount', commit);
	},
	async getIncomingUncheckedCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			destinationBusinessRelationId: businessRelationId,
			statusIds: ['Planned'],
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'incomingUncheckedItems', 'incomingUncheckedItemsCount', commit);
	},
	async getIncomingCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			destinationBusinessRelationId: businessRelationId,
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'incomingItems', 'incomingItemsCount', commit);
	},
	async getPlannedOnMonthCollectionOrders({ commit }, { businessRelationId, minDate, maxDate, page, pageSize, excel = false }) {
		const body = {
			// destinationBusinessRelationId: businessRelationId,
			statusIds: ['Planned'],
			minPlannedDate: minDate,
			maxPlannedDate: maxDate,
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'plannedOnMonthItems', 'plannedOnMonthItemsCount', commit);
	},
	getCollectionOrder({ commit }, { businessRelationId, collectionOrderId }) {
		const body = {
			collectionOrderId,
		};
		searchCollectionOrders(businessRelationId, body, 0, 1, 'items', 'itemsCount', commit);
	},
	async getBrandCollectionOrders({ commit }, { businessRelationId, brandId, ownerId, page, pageSize, excel = false }) {
		const body = {
			brandId,
			...(ownerId && { ownerId }),
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'brandItems', 'brandItemsCount', commit);
	},
	async getSpocCollectionOrders({ commit }, { businessRelationId, page, pageSize, excel = false }) {
		const body = {
			spocId: businessRelationId,
		};

		if (excel) {
			exportCollectionOrders(businessRelationId, body, commit);
			return;
		}

		searchCollectionOrders(businessRelationId, body, page, pageSize, 'spocItems', 'spocItemsCount', commit);
	},
	getPackaging({ commit }, { collectionOrderId, page, pageSize }) {
		const url = `/logistics/collectionorders/${collectionOrderId}/packagings?page=${page || 0}&pageSize=${pageSize || ITEMS_PER_PAGE.DEFAULT}`;
		apiService
			.get(url)
			.then(({ data: packaging }) => {
				commit('setPackaging', {
					collectionOrderId,
					items: packaging.items,
				});
			})
			.catch(err => {
				logAndToastError(commit, 'Failed to get packaging from the server', err, { url });
			});
	},
	resetCollectionOrders({ commit }) {
		commit('resetCollectionOrders');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setCollectionOrders(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setCollectionOrdersCount(state, { count, storeCountPath }) {
		set(state, storeCountPath, count);
	},
	setIsLoading(state, { isLoading }) {
		set(state, "isLoading", isLoading);
	},
	setPackaging(state, { collectionOrderId, items }) {
		Vue.set(state.packaging, collectionOrderId, items);
	},
	resetCollectionOrders(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
